import React, { useContext, useEffect, useRef, useState } from "react";
import "./patientAvailabilityBuilder.scss";
import { Button } from "@mui/material";
import Availabiity from "../../Availability/Availability";
import DaysBack from "assets/Days-back.svg";
import AvailabilityBuilder from "assets/Availability-Builder.svg";
import { AvailabilityCard } from "../../AvailiablilityCards/AvailiabilityCards";
import { myContext } from "features/Hooks/useContext";
import { useHistory } from "react-router-dom";
import helpGif from "assets/Availability-card.gif";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Dialog from "@mui/material/Dialog";
import { dayOfWeek } from "./staticAvailabilityData";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AvailabilityCardRef {
  handleValidate: () => void;
}

export const PatientAvailabilityBuilder: React.FC<{}> = () => {
  const {
    status,
    daysChecked,
    totalWeek,
    setTotalWeek,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thrusday,
    setThrusday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    validateUrl,
  } = useContext(myContext);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [emptyData, setEmptyData] = useState<boolean>();
  const refMonday = useRef<AvailabilityCardRef | null>(null);
  const refTuesday = useRef<AvailabilityCardRef | null>(null);
  const refWednesday = useRef<AvailabilityCardRef | null>(null);
  const refThursday = useRef<AvailabilityCardRef | null>(null);
  const refFriday = useRef<AvailabilityCardRef | null>(null);
  const refSaturday = useRef<AvailabilityCardRef | null>(null);

  const mapDay = (dayArray: any, defaultDay: any, dayOfWeek: any) =>
    !daysChecked[dayOfWeek]
      ? dayArray.length > 0
        ? dayArray.map((data: any) => ({
            dayOfWeek: data.dayOfWeek,
            startTime: data.startTime || "",
            endTime: data.endTime || "",
            locationInput: data.locationInput || data.status,
          }))
        : []
      : dayArray.length > 0
      ? dayArray.map((data: any) => ({
          dayOfWeek: data.dayOfWeek,
          startTime: data.startTime || "",
          endTime: data.endTime || "",
          locationInput: data.locationInput || data.status,
        }))
      : [
          {
            dayOfWeek: defaultDay,
            startTime: "",
            endTime: "",
            locationInput: status,
          },
        ];

  useEffect(() => {
    setMonday(mapDay(monday, dayOfWeek.monday, "Monday"));
    setTuesday(mapDay(tuesday, dayOfWeek.tuesday, "Tuesday"));
    setWednesday(mapDay(wednesday, dayOfWeek.wednesday, "Wednesday"));
    setThrusday(mapDay(thrusday, dayOfWeek.thursday, "Thursday"));
    setFriday(mapDay(friday, dayOfWeek.friday, "Friday"));
    setSaturday(mapDay(saturday, dayOfWeek.saturday, "Saturday"));
  }, [daysChecked]);

  useEffect(() => {
    setTotalWeek({
      monday: monday,
      tuesday: tuesday,
      wednesday: wednesday,
      thrusday: thrusday,
      friday: friday,
      saturday: saturday,
    });
  }, [monday, tuesday, wednesday, thrusday, friday, saturday, setTotalWeek]);

  const handleDaysBack = () => {
    history.push({
      pathname: "/appointments-availability-days",
    });
  };

  const HandleReviewNavigation = () => {
    const noDataForEveryDay = Object.values(totalWeek).every(
      (data: any) => data.length === 0
    );

    setEmptyData(noDataForEveryDay);

    refMonday.current?.handleValidate();
    refTuesday.current?.handleValidate();
    refWednesday.current?.handleValidate();
    refThursday.current?.handleValidate();
    refFriday.current?.handleValidate();
    refSaturday.current?.handleValidate();

    if (
      refMonday.current?.handleValidate() &&
      refTuesday.current?.handleValidate() &&
      refWednesday.current?.handleValidate() &&
      refThursday.current?.handleValidate() &&
      refFriday.current?.handleValidate() &&
      refSaturday.current?.handleValidate() &&
      noDataForEveryDay === false
    ) {
      history.push({
        pathname: "/appointments-availability-start",
      });
    }
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    validateUrl && history.replace("/appointments");
  }, [validateUrl, history]);

  return (
    <div className="primary-PatientAvailabilityBuilder-container">
      <div>
        <div className="Location-navigation">
          <div className="Navigation">
            <img
              className="Home-img"
              src={DaysBack}
              alt="Loading"
              onClick={handleDaysBack}
            />
          </div>
        </div>
        <Availabiity />
        <div className="secondary-PatientAvailabilityBuilder-container">
          <img
            className="AvailabilityBuilder-img"
            src={AvailabilityBuilder}
            alt="Loading"
          />
          <div className="primary-text">
            Thanks for telling us which days you’re available. For each day,
            tell us what time you’re open and where.{" "}
            <span className="help" onClick={handleOpen}>
              Need help ?
            </span>
          </div>
          <AvailabilityCard
            key="Monday"
            heading="Monday"
            locations={setMonday}
            locationData={monday}
            forwardedRef={refMonday}
            dayOfWeek={dayOfWeek.monday}
          />
          <AvailabilityCard
            key="Tuesday"
            heading="Tuesday"
            locations={setTuesday}
            locationData={tuesday}
            forwardedRef={refTuesday}
            dayOfWeek={dayOfWeek.tuesday}
          />
          <AvailabilityCard
            key="Wednesday"
            heading="Wednesday"
            locations={setWednesday}
            locationData={wednesday}
            forwardedRef={refWednesday}
            dayOfWeek={dayOfWeek.wednesday}
          />
          <AvailabilityCard
            key="Thursday"
            heading="Thursday"
            locations={setThrusday}
            locationData={thrusday}
            forwardedRef={refThursday}
            dayOfWeek={dayOfWeek.thursday}
          />
          <AvailabilityCard
            key="Friday"
            heading="Friday"
            locations={setFriday}
            locationData={friday}
            forwardedRef={refFriday}
            dayOfWeek={dayOfWeek.friday}
          />
          <AvailabilityCard
            key="Saturday"
            heading="Saturday"
            locations={setSaturday}
            locationData={saturday}
            forwardedRef={refSaturday}
            dayOfWeek={dayOfWeek.saturday}
          />
        </div>
        {emptyData === true && (
          <div className="validate-text">
            Please select at least one card to proceed
          </div>
        )}
      </div>
      <div className="Button-container">
        <Button
          className="Next-button"
          variant="contained"
          onClick={HandleReviewNavigation}
        >
          Next
        </Button>
      </div>
      <Dialog
        open={isOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <img src={helpGif} alt="loading" />
        <Button onClick={handleClose}>Got it</Button>
      </Dialog>
    </div>
  );
};
