import React from "react";
import { Redirect } from "react-router-dom";
import Loader from "../Loader/Loader";
import { useAuth0 } from "../../react-auth0-spa";

const Main = () => {
  const { loading, isAuthenticated, user } = useAuth0();
  if (loading) return <Loader />;
  if (!isAuthenticated) return <Redirect to="/login" />;
  else if (user["https://corticacare.com/app_metadata"].provider)
    return <Redirect to="/providerappointments" />;
  else return <Redirect to="/appointments" />;
};

export default Main;
