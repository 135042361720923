import React, { useContext, useEffect } from "react";
import Availabiity from "../../Availability/Availability";
import "./patientAvailabilityStart.scss";
import start from "assets/Availability-Start.svg";
import AvailabilityBuilderBack from "assets/Availability-Builder-Back.svg";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import { myContext } from "features/Hooks/useContext";
import { DatePicker } from "antd";

export const PatientAvailabilityStart: React.FC<{}> = () => {
  const { availabilityDate, setAvailiabilityDate, validateUrl } =
    useContext(myContext);
  const history = useHistory();
  const today = new Date();

  const onChange = (e: any) => {
    setAvailiabilityDate(e);
  };

  const HandleReviewNavigation = () => {
    history.push({
      pathname: "/appointments-availability-review",
    });
  };

  const HandleBuilderNavigation = () => {
    history.push({
      pathname: "/appointments-availability-builder",
    });
  };

  const disabledDate = (current: any) => {
    // If the date is before today, disable it
    return current && current < today.setHours(0, 0, 0, 0);
  };

  useEffect(() => {
    validateUrl && history.replace("/appointments");
  }, [validateUrl, history]);

  return (
    <div className="primary-PatientAvailabilityStart-container">
      <div>
        <div className="Location-navigation">
          <div className="Navigation">
            <img
              className="Home-img"
              src={AvailabilityBuilderBack}
              alt="Loading"
              onClick={HandleBuilderNavigation}
            />
          </div>
        </div>
        <Availabiity />
        <div className="secondary-PatientAvailabilitystart-container">
          <img className="Start-img" src={start} alt="Loading" />
          <div className="primary-text">
            Input the start date for this availability.
          </div>
          <div className="primary-date">
            <DatePicker
              value={availabilityDate}
              onChange={(e: any) => onChange(e)}
              allowClear={false}
              format="MM/DD/YYYY"
              placeholder="mm/dd/yyyy"
              disabledDate={disabledDate}
            />
          </div>
        </div>
      </div>
      <div className="Button-container">
        <Button
          className="Next-button"
          variant="contained"
          onClick={HandleReviewNavigation}
          disabled={!availabilityDate}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
