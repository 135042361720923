export const calenderViewOption = [
  { name: "Week", value: "Week" },
  { name: "3 Day", value: "Three Days" },
  { name: "Day", value: "Day" },
];

export const Days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const formatDateAndTime = (utcTimeString, isDate = false) => {
  let dateObj = new Date(utcTimeString);
  return isDate
    ? dateObj.toLocaleDateString(undefined, {
        weekday: "long",
        month: "long",
        day: "2-digit",
        year: "numeric",
      })
    : dateObj.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
};

export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
