import React from "react";
import "./Failure.sass";
import ErrorLogo from "assets/Error.png";

interface IProps {
  refresh: () => void;
}

const Failure: React.FC<IProps> = (props) => {
  return (
    <>
      <div className="error-wrapper">
        <div className="error">
          <div className="icon-wrapper">
            <img src={ErrorLogo} alt="errorLogo" />
          </div>
          <div className="text-wrapper">
            <p>Error</p>
            <p>The page didn’t load. Try to refresh the page.</p>
            <button className="link" onClick={props.refresh}>
              Refresh Page
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Failure;
