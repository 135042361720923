import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useAuth0 } from "./react-auth0-spa";
import Loading from "features/Loader/Loader";
import Appointments from "features/Appointments/Appointments";
import providerAppointmentsPage from "./features/Appointments/biportal/ProviderAppointments";
import AppointmentForm from "features/Appointments/biportal/AppointmentForms/AppointmentForm.jsx";
import reviewProviderDay from "./features/Appointments/biportal/AppointmentForms/ReviewProviderDay";
import Header from "features/Header/Header";
import Login from "features/Login/Login";
import PrivateRoute from "features/PrivateRoute";
import UnverifiedUser from "features/UnverifiedUser/UnverifiedUser";
import Main from "features/Main/Main";
import "./App.sass";
import Logout from "features/Logout/Logout";
import PasswordResetSuccessPage from "features/PasswordReset/PasswordResetSuccessPage";
import Footer from "features/Footer/Footer";
import CancelAppointment from "features/Appointments/biportal/AppointmentForms/CancelAppointment";
import EditAppointment from "features/Appointments/biportal/AppointmentForms/EditAppointment";
// import { CrDownTimeData } from "utils/Cache/CrBannerVar";
import { useWebSocket } from "utils/WebSockets/WebSocketProvider";
import { getSocketUrl } from "utils/WebSockets/socket.helper";
import { PatientAvailabiity } from "features/Appointments/Calendar/PatientAvailability/PatientAvailability";
import { PatientAvailabilityBuilder } from "features/Appointments/Calendar/PatientAvailability/PatientAvailabilityBuilder";
import { PatientAvailabiityDay } from "features/Appointments/Calendar/PatientAvailability/patientAvailabilityDays";
import { PateintAvailabilityReview } from "features/Appointments/Calendar/PatientAvailability/PatientAvailabilityReview";
import { PatientAvailabilityClose } from "features/Appointments/Calendar/PatientAvailability/PatientAvailabilityClose";
import { PatientAvailabilityStart } from "features/Appointments/Calendar/PatientAvailability/PatientAvailabilityStart";
import { PatientAvailabilityBuilderReview } from "features/Appointments/Calendar/PatientAvailability/PatientAvailabilityBuilderReview";
import { EditPatientAvailabilityBuilderReview } from "features/Appointments/Calendar/PatientAvailability/EditPatientAvailabilityReview";
import { RedirectAvailability } from "features/Appointments/Calendar/PatientAvailability/redirectAvailability";
import Availability from "../src/features/Appointments/Availability/Availability";
import AllAppointmentsList from "features/Appointments/Calendar/Supporting-Components/AllAppointmentsList";
import Navbar from "features/Header/Navbar";
import { MyContextProvider } from "features/Hooks/useContext";
import { ClientReschedule } from "features/Appointments/Calendar/RescheduleAppointments/ClientReschedule";
import { ClientRescheduleBusy } from "features/Appointments/Calendar/RescheduleAppointments/ClientRescheduleBusy";
import { ClientRescheduled } from "features/Appointments/Calendar/RescheduleAppointments/ClientRescheduled";
import { ClientRescheduleProblem } from "features/Appointments/Calendar/RescheduleAppointments/ClientRescheduleProblem";
import { ClientRescheduleLate } from "features/Appointments/Calendar/RescheduleAppointments/ClientRescheduleLate";
import { ClientRescheduleConfirm } from "features/Appointments/Calendar/RescheduleAppointments/ClientRescheduleConfirm";
import { ClientRescheduleFlow } from "features/Appointments/Calendar/RescheduleAppointments/clientRescheduleFlow";
import { ClientRescheduleNotAvailable } from "features/Appointments/Calendar/RescheduleAppointments/clientRescheduleNotAvailable";

function App() {
  const { loading, user } = useAuth0();
  const location = useLocation();
  const environment = process.env.REACT_APP_STAGE;
  const { updateWebSocketData, webSocketData } = useWebSocket();

  const socketUrl = getSocketUrl(environment);

  useEffect(() => {
    let socket; // Declare the socket variable

    const establishWebSocketConnection = () => {
      if (user?.email) {
        const userEmail = user.email;

        socket = new WebSocket(socketUrl);

        socket.onopen = (event) => {
          try {
            socket.send(JSON.stringify({ action: "setName", name: userEmail }));
          } catch (error) {
            console.log(error, "[establishWebSocketConnection] error", error);
            establishWebSocketConnection();
          }
        };

        socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          if (
            message.data.socketRouteMethod === "DownStreamStatus" ||
            message.data.socketRouteMethod === "setName"
          ) {
            updateWebSocketData(message.data.downStreamStatus);
          }
          if (!message.data.isConnected) {
            establishWebSocketConnection();
          }
        };

        socket.onclose = (event) => {
          // Open a new WebSocket connection when the previous one is closed
          establishWebSocketConnection();
        };
      }
    };

    // Initial WebSocket connection establishment
    establishWebSocketConnection();

    // Cleanup function to close the WebSocket connection when the component is unmounted
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [user?.email]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <section className="App">
        <div className="sticky-header">
          {location.pathname !== "/logout" &&
            location.pathname !== "/password-reset-success" && <Navbar />}
        </div>
        <div className="body-content">
          <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/unverified_user" exact component={UnverifiedUser} />
            <Route path="/login" component={Login} />
            <Route path="/logout" component={Logout} />
            <Route
              path="/password-reset-success"
              component={PasswordResetSuccessPage}
            />
            <PrivateRoute
              path="/add-new-appointment"
              component={AppointmentForm}
            />
            <PrivateRoute
              path="/cancel-appointment"
              component={CancelAppointment}
            />
            <PrivateRoute
              path="/providerappointments"
              component={providerAppointmentsPage}
            />
            <PrivateRoute
              path="/confirm-schedule"
              component={reviewProviderDay}
            />
            <PrivateRoute
              path="/edit-appointment"
              component={EditAppointment}
            />
            <MyContextProvider>
              <PrivateRoute path="/appointments" component={Appointments} />
              <PrivateRoute path="/avail" component={Availability} />
              {/*Availability Flow */}
              <PrivateRoute
                path="/redirect-availability"
                component={RedirectAvailability}
              />
              <PrivateRoute
                path="/appointments-availability"
                component={PatientAvailabiity}
              />
              <PrivateRoute
                path="/appointments-availability-start"
                component={PatientAvailabilityStart}
              />
              <PrivateRoute
                path="/appointments-availability-builder"
                component={PatientAvailabilityBuilder}
              />
              <PrivateRoute
                path="/appointments-availability-days"
                component={PatientAvailabiityDay}
              />
              <PrivateRoute
                path="/appointments-availability-review"
                component={PateintAvailabilityReview}
              />
              <PrivateRoute
                path="/appointments-availability-builder-review"
                component={PatientAvailabilityBuilderReview}
              />
              <PrivateRoute
                path="/appointments-availability-close"
                component={PatientAvailabilityClose}
              />
              <PrivateRoute
                path="/edit-appointments-availiability"
                component={EditPatientAvailabilityBuilderReview}
              />
              <PrivateRoute
                path="/client-appointments"
                component={AllAppointmentsList}
              />
              {/* reschedule Flow */}
              <PrivateRoute
                path="/client-reschedule-redirect"
                component={ClientRescheduleFlow}
              />
              <PrivateRoute
                path="/client-reschedule-appointment"
                component={ClientReschedule}
              />
              <PrivateRoute
                path="/client-reschedule-confirm"
                component={ClientRescheduleConfirm}
              />
              <PrivateRoute
                path="/client-reschedule-busy"
                component={ClientRescheduleBusy}
              />
              <PrivateRoute
                path="/client-rescheduled"
                component={ClientRescheduled}
              />
              <PrivateRoute
                path="/client-reschedule-problem"
                component={ClientRescheduleProblem}
              />
              <PrivateRoute
                path="/client-reschedule-not-available"
                component={ClientRescheduleNotAvailable}
              />
              <PrivateRoute
                path="/client-reschedule-late"
                component={ClientRescheduleLate}
              />
            </MyContextProvider>
          </Switch>
          {location.pathname !== "/logout" &&
            location.pathname !== "/password-reset-success" &&
            location.pathname !== "/providerappointments" &&
            location.pathname !== "/add-new-appointment" &&
            location.pathname !== "/confirm-schedule" &&
            location.pathname !== "/cancel-appointment" &&
            location.pathname !== "/edit-appointment" &&
            location.pathname !== "/appointments-availability-days" &&
            location.pathname !== "/appointments-availability-builder" &&
            location.pathname !== "/appointments-availability" &&
            location.pathname !== "/appointments-availability-review" &&
            location.pathname !== "/appointments-availability-start" &&
            location.pathname !== "/appointments-availability-builder-review" &&
            location.pathname !== "/appointments-availability-close" &&
            location.pathname !== "/edit-appointments-availiability" &&
            location.pathname !== "/redirect-availability" &&
            location.pathname !== "/avail" &&
            location.pathname !== "/client-reschedule-redirect" &&
            location.pathname !== "/client-reschedule-appointment" &&
            location.pathname !== "/client-reschedule-busy" &&
            location.pathname !== "/client-rescheduled" &&
            location.pathname !== "/client-reschedule-problem" &&
            location.pathname !== "/client-reschedule-late" &&
            location.pathname !== "/client-reschedule-confirm" &&
            location.pathname !== "/client-reschedule-not-available" && (
              <Footer />
            )}
        </div>
      </section>
    </div>
  );
}

export default App;
