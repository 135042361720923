import { formatDateAndTime } from "constant/calenderView";
import { Appointment } from "model/appointments";
import React, { useContext } from "react";
import "./ClientAppointment.scss";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Popover } from "@mui/material";
import dots from "assets/DotsThreeVertical.svg";
import { myContext } from "features/Hooks/useContext";
import { default_Dropdown } from "../RescheduleAppointments/staticRescheduleData";

interface IProps {
  key: number;
  appointment: Appointment;
  patientClinic?: number[];
  clientClinicData?: any;
  activeDay: string;
  path: string;
}

const ClientAppointmentBar: React.FC<IProps> = ({
  key,
  appointment,
  patientClinic,
  clientClinicData,
  activeDay,
  path,
}) => {
  console.log(
    "sravan log",
    key,
    appointment,
    appointment?.color,
    patientClinic,
    clientClinicData,
    activeDay
  );

  console.log(
    patientClinic,
    clientClinicData,
    "[clientAppointmentBar] clinicData"
  );

  console.log(patientClinic?.includes(clientClinicData), "indcludes log");
  const { setState, setValidateUrl } = useContext(myContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const History = useHistory();

  const withInDay = moment(appointment?.startAt).diff(moment(), "hours") <= 24;

  console.log(withInDay, "withInday");

  const snackColor = appointment?.color ? appointment.color : "#1d1d1f";

  const isTelehealth = appointment.location_type === "Telehealth";

  const address = appointment.address;
  const apptAddress = [
    address?.addressLine1,
    address?.addressLine2,
    address?.city,
    address?.state,
    address?.zipCode,
  ]
    .filter((part) => part !== undefined && part !== "" && part !== null)
    .join(", ");

  const openMaps = (event: any) => {
    event.stopPropagation();
    const encodedAddress = encodeURIComponent(apptAddress);
    const googleMapsUrl = `https://www.google.com/maps?q=${encodedAddress}`;
    window.open(googleMapsUrl, "_blank");
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRescheduleNavigation = () => {
    setValidateUrl(false);
    setState((prevData: any) => ({
      ...prevData,
      selectedDate: null,
      initialDateSet: false,
      clickedIndex: -1,
      dropdown: default_Dropdown,
      textArea: "",
      rcSuggestions: undefined,
      error: false,
      newStartTime: undefined,
      newEndTime: undefined,
    }));
    History.push({
      pathname: !withInDay
        ? "/client-reschedule-redirect"
        : "/client-reschedule-late",
      state: {
        appointment: appointment,
        activeDay: activeDay,
        path: path,
      },
    });
  };

  // const handleCancelnavigation = () => {
  //   History.push({
  //     pathname: "/client-cancel-appointment",
  //   });
  // };

  return (
    <>
      <div className="appointment-bar">
        <div
          className="stack-1 color-indication"
          style={{ backgroundColor: snackColor }}
        ></div>
        <div className="stack-2 clock-data uniform-font-size fn-bold">
          <div className="h-center">
            <span>{`${formatDateAndTime(
              appointment.startAt
            ).toLocaleLowerCase()}`}</span>
          </div>
          <div className="h-center">
            <span>{`${formatDateAndTime(
              appointment.endAt
            ).toLocaleLowerCase()}`}</span>
          </div>
        </div>
        <div className="stack-3 other-information uniform-font-size">
          <div className="v-center div-4 fn-md">
            <span className="appt-title">{`${
              appointment.client_name + "'s" + " " + appointment?.title
            }`}</span>
          </div>
          <div className="address-information v-center fn-sm div-3">
            {!isTelehealth ? (
              <a className="cur-pointer" onClick={(event) => openMaps(event)}>
                {apptAddress}
              </a>
            ) : (
              <a
                className="cur-pointer"
                href={appointment?.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {appointment?.link}
              </a>
            )}
          </div>
          <div className="v-center fn-sm div-3">
            <span>{`${appointment.provider_name.replace(/,/g, "")}`}</span>
          </div>
        </div>
        {patientClinic?.some((element) =>
          clientClinicData.includes(element)
        ) ? (
          <div className="stack-4">
            <img
              className="dots-img"
              src={dots}
              alt="dots"
              onClick={handleClick}
            />
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "center",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="popover-menu-clientAppointement-Bar">
                <span
                  // className={withInDay ? "disabled-option" : ""}
                  onClick={handleRescheduleNavigation}
                >
                  Reschedule Appointment{" "}
                </span>
              </div>
              {/* <div className="popover-menu-clientAppointement-Bar">
                <span
                  // className={withInDay ? "disabled-option" : ""}
                  onClick={handleCancelnavigation}
                >
                  Cancel Appointment
                </span>
              </div> */}
            </Popover>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default React.memo(ClientAppointmentBar);
