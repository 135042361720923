import {
  AdminAppointmentForm,
  AdminAppointmentsList,
  DropdownOption,
  CreateApptPayload,
  Appointment,
  FormatTimeString,
  AddressInterface,
} from "model/appointments";
import moment, { Moment } from "moment";
import {
  CR_SYNCING_CODES,
  CR_FAILURE_CODES,
  CR_CANCELLING_CODE,
  CR_CREATING_CODE,
  CR_UPDATING_CODE,
  CR_SUCCESS_CODES,
  ADMIN_APPOINTMENTS_ARRAY,
} from "constant/BiPortalConstants";
import dayjs from "dayjs";

export const initValues = (apptList: DropdownOption[]) => {
  const momentDate = moment().format();

  return {
    selectedDay: momentDate,
    startDate: undefined,
    endDate: undefined,
    appointmentType: apptList[0].value,
    isFirstLastDrive: null,
  };
};

export const mapToValuedOption = (apptList: AdminAppointmentsList[]) => {
  const environment = process.env.REACT_APP_STAGE;
  return apptList?.map((appt) => {
    if (environment === "local") {
      return {
        value: appt.dev.id,
        label: appt.value,
      };
    } else if (environment === "dev") {
      return {
        value: appt.dev.id,
        label: appt.value,
      };
    } else if (environment === "test") {
      return {
        value: appt.test.id,
        label: appt.value,
      };
    } else if (environment === "uat") {
      return {
        value: appt.uat.id,
        label: appt.value,
      };
    } else {
      return {
        value: appt.prod.id,
        label: appt.value,
      };
    }
  });
};

export const getUtcTime = (time: string) => {
  const newDate = moment(new Date());
  const TimeHours = Number(time.split(":")[0]);
  const TimeMinutes = Number(time.split(":")[1]);
  const dateFormed = newDate
    .clone()
    .set("hours", TimeHours)
    .set("minutes", TimeMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);
  return dateFormed.toISOString();
};

export const adjustDateHours = (date: string, time: Moment) => {
  // const momentStartDate = moment.utc(date); // Convert date string to UTC
  // const momentStartTime = moment.utc(time); // Convert moment time to UTC

  const momentStartDate = moment(new Date(date));
  const momentStartTime = moment(time);

  const hourMinutes = momentStartTime.format("HH:mm");
  const hours = Number(hourMinutes.split(":")[0]);
  const minutes = Number(hourMinutes.split(":")[1]);

  const formedDate = momentStartDate
    .clone()
    .set("hours", hours)
    .set("minutes", minutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  return formedDate.toISOString();
};

export const getCreateAppointmentPayload = (
  payload: CreateApptPayload,
  formData: AdminAppointmentForm
) => {
  const startTimeBeforeFormating = moment(formData.startDate);
  const endTimeBeforeFormating = moment(formData.endDate);

  let formattedStartTime = startTimeBeforeFormating.format(" HH:mm");
  let formattedEndTime = endTimeBeforeFormating.format("HH:mm");

  const appointmentDate = moment(new Date(formData.selectedDay));

  const apptStartTimeHours = Number(formattedStartTime.split(":")[0]);
  const apptEndTimeHours = Number(formattedEndTime.split(":")[0]);
  const apptStartMinutes = Number(formattedStartTime.split(":")[1]);
  const apptEndTimeMinutes = Number(formattedEndTime.split(":")[1]);

  const newStartDate = appointmentDate
    .clone()
    .set("hours", apptStartTimeHours)
    .set("minutes", apptStartMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  const newEndDate = appointmentDate
    .clone()
    .set("hours", apptEndTimeHours)
    .set("minutes", apptEndTimeMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  const isDriveTime =
    Number(formData.appointmentType) === 3 ||
    Number(formData.appointmentType) === 159 ||
    Number(formData.appointmentType) === 276 ||
    Number(formData.appointmentType) === 364 ||
    Number(formData.appointmentType) === 455;

  const duration = moment.duration(newEndDate.diff(newStartDate)).asMinutes();

  return {
    startDate: newStartDate.toISOString(),
    endDate: newEndDate.toISOString(),
    providerId: payload.providerId,
    clinicId: payload.providerClinicId,
    appointmentTypeId: formData.appointmentType,
    duration: duration,
    providerName: payload.providerName,
    providerEmail: payload.providerEmail,
    note: formData.note,
    startingAddress: isDriveTime ? formData?.startingAddress : null,
    destinationAddress: isDriveTime ? formData?.destinationAddress : null,
    miles: isDriveTime ? formData?.miles : null,
    reimburseableMiles: isDriveTime
      ? formData?.isFirstLastDrive
        ? formData?.reimburseableMiles
        : formData?.miles
      : null,
    isLastDrive: isDriveTime ? formData?.isFirstLastDrive : null,
    isDriveTime: isDriveTime,
  };
};

export const nullAddressCheck = (address: AddressInterface) => {
  if (address.freeformAddress === "" || address.lat === "") {
    return false;
  } else {
    return true;
  }
};

export const addDriveTimeSpecificPayload = (payload: any, formData: any) => {
  return {
    ...payload,
    startingAddress: formData?.startingAddress,
    destinationAddress: formData?.destinationAddress,
    miles: formData?.miles,
    reimburseableMiles: formData?.reimburseableMiles,
    isLastDrive: formData?.isFirstLastDrive,
    isDriveTime: true,
  };
};

export const cancelAppointmentPayload = (
  payload: CreateApptPayload,
  cancelReason: string,
  displayCancelReason: string,
  appointment: Appointment,
  note?: string
) => {
  let adminDirectAppointmentId;
  const environment = process.env.REACT_APP_STAGE || "prod";
  const adminDirectAppointmentConfig: any = ADMIN_APPOINTMENTS_ARRAY.find(
    (appointment) => appointment.value === "Admin Direct"
  );
  if (adminDirectAppointmentConfig && environment !== "local") {
    adminDirectAppointmentId = adminDirectAppointmentConfig[environment].id;
  } else {
    adminDirectAppointmentId = 1;
  }

  return {
    eventId: appointment.id,
    leadEventId: payload.leadEventId,
    providerId: appointment.provider_id,
    cancelNote: note ? note : "Cancelling from BI Application",
    providerName: payload.providerName,
    providerEmail: payload.providerEmail,
    cancelReasonId: cancelReason,
    displayCancelReason: displayCancelReason,
    appointmentType: payload.appointmentType,
    startDate: payload.startDate,
    endDate: payload.endDate,
    duration: moment
      .duration(moment(payload.endDate).diff(moment(payload.startDate)))
      .asMinutes(),
    clinicId: payload.clinicId,
    adminDirectAppointmentId: adminDirectAppointmentId,
  };
};

export const getTimeFromIso = (start: string, end: string) => {
  const startTime = moment(new Date(start)).format("hh:mm A");
  const endTime = moment(new Date(end)).format("hh:mm A");
  return {
    startTime: startTime,
    endTime: endTime,
  };
};

export const getTimeFromStrings = (formData: FormatTimeString) => {
  const appointmentDate = moment(formData.startTime);

  const startTimeBeforeFormating = moment(formData.startTime);
  const endTimeBeforeFormating = moment(formData.endTime);

  let formattedStartTime = startTimeBeforeFormating.format("HH:mm");
  let formattedEndTime = endTimeBeforeFormating.format("HH:mm");

  const [hours, minutes] = formattedStartTime.split(":");
  const [endhours, endminutes] = formattedEndTime.split(":");

  const apptStartTimeHours = Number(hours);

  const apptEndTimeHours = Number(endhours);

  const apptStartMinutes = Number(minutes);

  const apptEndTimeMinutes = Number(endminutes);

  // ------------------

  const newStartDate = appointmentDate
    .clone()
    .set("hours", apptStartTimeHours)
    .set("minutes", apptStartMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  const newEndDate = appointmentDate
    .clone()
    .set("hours", apptEndTimeHours)
    .set("minutes", apptEndTimeMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  return {
    startTime: newStartDate.format("hh:mm a"),
    endTime: newEndDate.format("hh:mm a"),
  };
};

export const checkIfItisToday = (date: string) => {
  const timestamp = new Date(date);

  const currentDate = new Date();

  if (
    timestamp.getFullYear() === currentDate.getFullYear() &&
    timestamp.getMonth() === currentDate.getMonth() &&
    timestamp.getDate() === currentDate.getDate()
  ) {
    return false;
  } else if (timestamp < currentDate) {
    return false;
  } else {
    return true;
  }
};

export const getCrStatusString = (
  code: number,
  isCancelling?: boolean,
  isHardCancelling?: boolean
) => {
  if (CR_FAILURE_CODES.includes(code)) {
    return "Failed";
  } else if (code === CR_CREATING_CODE) {
    return "Creating";
  } else if (code === CR_UPDATING_CODE && !isHardCancelling) {
    return "Updating";
  } else if (isHardCancelling) {
    return "Cancelling";
  } else if (
    CR_CANCELLING_CODE.includes(code) ||
    (isCancelling && !CR_SUCCESS_CODES.includes(code))
  ) {
    return "Canceling";
  } else {
    return;
  }
};

export const syncingStrings = [];

export const isEventSyncing = (
  code: number,
  isCancelling?: boolean,
  isHardCancelling?: boolean
) => {
  if (
    CR_CANCELLING_CODE.includes(code) ||
    CR_SYNCING_CODES.includes(code) ||
    isCancelling ||
    isHardCancelling
  ) {
    return true;
  } else {
    return false;
  }
};

export const isFailedToSync = (code: number) => {
  if (CR_FAILURE_CODES.includes(code)) {
    return true;
  } else {
    return false;
  }
};

export const getFormattedHHmm = (hour: string | number) => {
  const formattedHour = hour < 10 ? `0${hour}` : hour.toString();
  return formattedHour;
};

export const getEditAppointmentInitValues = (appointment: Appointment) => {
  const isAdmin = appointment.appointment_type === "Admin";
  const isDriveTime =
    appointment.title === "Drive Time" || appointment.title === "Mileage Only";

  const startDate = new Date(appointment.startAt);
  const endDate = new Date(appointment.endAt);
  const startHours = dayjs(startDate);

  const endHours = dayjs(endDate);

  const commonApptData = {
    origialStart: startHours,
    originalEnd: endHours,
    startTime: startHours,
    endTime: endHours,
    note: appointment.note?.note,
  };

  const adminSpecificValues = {
    appointmentType: appointment?.type_id,
    isFirstLastDrive: isDriveTime
      ? appointment?.isFirstOrLastDriveToNonClinic
      : null,
  };

  if (isAdmin) {
    return { ...commonApptData, ...adminSpecificValues };
  } else {
    return commonApptData;
  }
};

export const compareTimes = (time1: any, time2utc: string, odd?: boolean) => {
  if (odd) {
    return time1.toISOString() === time2utc ? true : false;
  } else {
    return time1.toISOString() === time2utc ? false : true;
  }
};

export const isIsoStringGreaterThan = (date1: Date, date2: Date): boolean => {
  // Use the getTime method to compare dates
  return date1.getTime() > date2.getTime();
};

export const GetUpdateCoursePayload = (
  appointment: Appointment,
  isAdmin: boolean,
  email: string,
  formData: any
) => {
  const appointmentDate = moment(new Date(appointment.startAt));

  const apptStartTimeHours = Number(
    formData.startDate.format("HH:mm").split(":")[0]
  );
  const apptEndTimeHours = Number(
    formData.endDate.format("HH:mm").split(":")[0]
  );
  const apptStartMinutes = Number(
    formData.startDate.format("HH:mm").split(":")[1]
  );
  const apptEndTimeMinutes = Number(
    formData.endDate.format("HH:mm").split(":")[1]
  );

  const newStartDate = appointmentDate
    .clone()
    .set("hours", apptStartTimeHours)
    .set("minutes", apptStartMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  const newEndDate = appointmentDate
    .clone()
    .set("hours", apptEndTimeHours)
    .set("minutes", apptEndTimeMinutes)
    .set("seconds", 0)
    .set("milliseconds", 0);

  const isDriveTime =
    appointment.title === "Drive Time" || appointment.title === "Mileage Only";

  const isAdminNPole = isAdmin ? false : true;

  const hasStartChanged = compareTimes(formData.startDate, appointment.start);

  const hasEndChanged = compareTimes(formData.endDate, appointment.end);

  const duration = moment.duration(newEndDate.diff(newStartDate)).asMinutes();

  const result = {
    eventId: appointment.id,
    clinicPreference: appointment.clinicPreference,
    isPendingConfirmation: false,
    originalStartDate: appointment.start,
    originalEndDate: appointment.end,
    startDate: newStartDate.toISOString(),
    endDate: newEndDate.toISOString(),
    duration: duration,
    editNote: `Patient Time Adjustment from Bi Portal by ${appointment.provider_name}`,
    locationType: isAdmin ? null : appointment.location_type,
    paymentMethod: isAdmin ? null : appointment.paymentMethod,
    locationCategory: appointment.location_category,
    appointmentTypeId: appointment.type_id,
    providerId: appointment.provider_id,
    clientId: isAdmin ? null : appointment.client_id,
    clinicId: appointment.clinic.id,
    cancelNote: "Cancel from Bi Portal",
    providerName: appointment.provider_name,
    providerEmail: email,
    reason: isAdmin && formData?.reason ? formData.reason : null,
    startReason: isAdminNPole && hasStartChanged ? formData.startReason : null,
    endReason: isAdminNPole && hasEndChanged ? formData.endReason : null,
    address: appointment.address,
    note: { id: appointment?.note?.id, note: formData.note },
    telehealthLink:
      isAdmin || appointment.location_type !== "Telehealth"
        ? null
        : appointment?.telehealthLink,
    startingAddress: isDriveTime ? formData?.startingAddress : null,
    destinationAddress: isDriveTime ? formData?.destinationAddress : null,
    miles: isDriveTime ? formData?.miles.toString() : null,
    reimburseableMiles: isDriveTime
      ? formData?.isFirstLastDrive
        ? formData?.reimburseableMiles
        : formData?.miles
      : null,
    isLastDrive: isDriveTime ? formData?.isFirstLastDrive : null,
    isDriveTime: isDriveTime,
  };

  return result;
};
