import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "./calendarView.scss";
import moment from "moment";
import SpinnerV2 from "../spinnerV2/spinnerV2";
import { calenderViewOption, Days } from "../../../constant/calenderView";
import { appointments } from "model/calender/appointments";
import {
  ButtonDates,
  ClickPosition,
  ClientColor,
  Idate,
} from "model/calender/calenderView";
import { EventApi } from "@fullcalendar/core";
import { debounce } from "lodash";
import AppointmentDetails from "./AppointmentDetails";
import { isMobile } from "react-device-detect";
import {
  faAngleLeft,
  faAngleRight,
  faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClientAppointmentBar from "./Supporting-Components/ClientAppointmentBar";
import { useHistory } from "react-router-dom";
import { myContext } from "features/Hooks/useContext";

interface IProps {
  appointments: appointments[];
  handleDataChange: (startStr: string, endStr: string) => void;
  softloading: boolean;
  patientClinic: number[];
  clientClinicData: any;
}

const CalendarView: React.FC<IProps> = ({
  appointments,
  handleDataChange,
  softloading,
  patientClinic,
  clientClinicData,
}) => {
  const { prefDate, setPrefDate } = useContext(myContext);
  const history = useHistory();
  const [showAppointmentDetails, setShowAppointmentDetails] =
    useState<Boolean>(false);
  const [eventDetail, setEventDetail] = useState<appointments | null>(null);
  const [coloredAppointments, setColoredAppointments] = useState<any>([]);
  const [clientColorMap, setClientColorMap] =
    useState<Record<string, ClientColor>>();
  const [clickPosition, setClickPosition] = useState<ClickPosition>({
    x: 0,
    y: 0,
  });
  const [activeEvent, setActiveEvent] = useState<string | null>(null);
  const [buttonDates, setButtonDates] = useState<ButtonDates[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [selectedOption, setSelectedOption] = useState<string>(
    calenderViewOption[2].value
  );
  const [activeDay, setActiveDay] = useState<string>(new Date().toString());
  const [sunday, setSunday] = useState<number>(0);

  const calendarRef = useRef<null>(null);
  const buttonDatesRef = useRef<ButtonDates[]>(buttonDates);
  const startDateRef = useRef<string>(startDate);
  const endDateRef = useRef<string>(endDate);
  const sundayRef = useRef<number>(sunday);

  const calendarRefCustom = useRef<any>(null);
  const [currentDate, setCurrentDate] = useState<string>(new Date().toString());
  const [currentDay, setcurrentDay] = useState<any>();
  const [currentMonthYear, setCurrentMonthYear] = useState<any>(
    moment().format("MMMM YYYY")
  );
  const path = "appointments";

  const refdate1 = prefDate
    ? moment(prefDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    : undefined;

  console.log(refdate1, "refdate in calenderview");

  const handlePrevButtonClick = () => {
    if (calendarRefCustom.current && calendarRefCustom.current.getApi()) {
      calendarRefCustom.current.getApi().prev();
    }
  };

  const handleNextButtonClick = () => {
    if (calendarRefCustom.current && calendarRefCustom.current.getApi()) {
      calendarRefCustom.current.getApi().next();
    }
  };

  const navigateToCurrentDay = () => {
    if (calendarRefCustom.current && calendarRefCustom.current.getApi()) {
      calendarRefCustom.current.getApi().today();
    }
  };

  // Inside the useEffect to add event listener for "Today" button
  useEffect(() => {
    setCurrentDate(new Date().toISOString());
    const todayButton = document.querySelector(".fc-today-button");
    if (todayButton) {
      todayButton.addEventListener("click", handleTodayButtonClick);
    }

    return () => {
      if (todayButton) {
        todayButton.removeEventListener("click", handleTodayButtonClick);
      }
    };
  }, []);

  useEffect(() => {
    if (appointments) {
      const uniqueClients: Set<number> = new Set(
        appointments.map((a) => a.client_id)
      );

      // Initialize the color map
      const colors: string[] = ["#4a70c6", "#f8d185"];
      let colorCounter: number = 0;
      let clientColorMap: Record<string, ClientColor> = {};

      uniqueClients.forEach((clientId) => {
        const clientAppointment = appointments.find(
          (app) => app.client_id === clientId
        );
        clientColorMap[clientId] = {
          color: colors[colorCounter % colors.length], // Assign a color from the array
          name: clientAppointment ? clientAppointment.client_name : "Unknown",
        };
        colorCounter++;
      });

      // Apply colors to appointments
      appointments.forEach((event: any) => {
        event.color = clientColorMap && clientColorMap[event.client_id]?.color;
      });
      // Set the client color map and colored appointments
      setClientColorMap(clientColorMap);
      setColoredAppointments([...appointments]);
    }
  }, [appointments]);

  const seeAllAppts = () => {
    // Calculate the start date of the week containing the activeDay
    const activeDayMoment = moment(activeDay, "ddd MMM DD YYYY");
    const currentWeekStart = activeDayMoment.startOf("week").toISOString();

    setActiveDay(currentWeekStart);

    // Navigate to see all appointments with the start date of the current week
    history.push({
      pathname: "/client-appointments",
      state: {
        prefStartDate: currentWeekStart,
      },
    });
  };

  const handleEventClick = (info: any) => {
    const targetElement = info.el;
    const targetRect = targetElement.getBoundingClientRect();

    const popUpX =
      targetRect.left + window.scrollX < window.innerWidth / 2
        ? targetRect.right + window.scrollX - 18
        : targetRect.left + window.scrollX - 220;
    const popUpY: number = targetRect.top + window.scrollY;

    setClickPosition({ x: popUpX, y: popUpY });
    setEventDetail(info?.event?.extendedProps);
    setShowAppointmentDetails(true);
    if (activeEvent === info.event.id) {
      setActiveEvent(null);
    } else {
      setActiveEvent(info.event.id);
      if (!isMobile) info.el.classList.add("active-event");
    }
  };

  const handleEventContent = ({ event }: { event: EventApi }) => {
    return (
      <div className="appt-card">
        <p className={isMobile ? "appt-type" : "appt-type-desktop"}>
          {event?.title}
        </p>
        <p className="appt-location">
          {event?.extendedProps.location_type === "In Clinic"
            ? `${event?.extendedProps?.clinic?.name} Clinic`
            : event?.extendedProps.location_type === "Off-Site"
            ? `${event?.extendedProps?.location_category}`
            : `TeleHealth`}
        </p>
        <p className="appt-provider">
          {event?.extendedProps?.provider_name.split(",").join(" ")}
        </p>
      </div>
    );
  };

  const getClientColor = (clientId: number) => {
    return clientColorMap && clientColorMap[clientId].color;
  };

  const handleEventDidMount = (info: any) => {
    const color = getClientColor(info.event.extendedProps.client_id);
    const colorClass = color === "#e1efff" ? "blue-event" : "yellow-event";
    info.el.classList.add(colorClass);
  };

  const handleSelect = (event: any) => {
    const startDate = startDateRef.current;
    const endDate = endDateRef.current;

    if (
      event.target.value !== "Three Days" &&
      selectedOption !== "Three Days" &&
      event.target.value !== "Day"
    ) {
      handleDataChange(startDate, endDate);
    }
    setSelectedOption(event.target.value);
  };

  const debouncehandleDataChange = debounce(
    (startDate: string, endDate: string) => {
      handleDataChange(startDate, endDate);
    },
    100
  );

  const handlePlannerDateViewChange = useCallback(
    (dateArgs: any) => {
      const { startStr, endStr } = dateArgs;
      const parsedStartData: moment.Moment = moment(startStr);
      const formattedStartDate: string = parsedStartData.format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      parsedStartData.set({ minute: 0, second: 0, millisecond: 0 });
      let start = updateMonthYear(parsedStartData.toDate());
      const parsedEndData: moment.Moment = moment(endStr);
      const formattedEndDate: string = parsedEndData.format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      );
      parsedEndData.set({ minute: 0, second: 0, millisecond: 0 });
      let end = updateMonthYear(parsedEndData.toDate());
      const currentWeek: number = moment().isoWeek();
      const currentDate: any = prefDate
        ? moment(prefDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]").toString()
        : new Date().toString();
      console.log(currentDate.toString(), "[calenderView] currentDate");
      const formattedWeek = parsedEndData.isoWeek();
      const mondayDateOfEveryWeek = prefDate
        ? moment(prefDate).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
        : moment().week(formattedWeek).day("Monday");

      if (start === end) {
        setCurrentMonthYear(start);
      } else {
        setCurrentMonthYear([(start = start), (end = end)]);
      }
      if (selectedOption === "Three Days" || selectedOption === "Week") {
        debouncehandleDataChange(formattedStartDate, formattedEndDate);
      } else if (selectedOption === "Day" && currentWeek !== formattedWeek) {
        setActiveDay(mondayDateOfEveryWeek.toString());
        setcurrentDay(false);
        handleDataChange(
          moment(mondayDateOfEveryWeek).startOf("day").toISOString(),
          moment(mondayDateOfEveryWeek).endOf("day").toISOString()
        );
      } else if (selectedOption === "Day" && currentWeek === formattedWeek) {
        setActiveDay(currentDate);
        handleDataChange(
          moment(currentDate).startOf("day").toISOString(),
          moment(currentDate).endOf("day").toISOString()
        );
        setcurrentDay(true);
      }
      setStartDate(startStr);
      setEndDate(endStr);
      setPrefDate(undefined);
    },
    [selectedOption, debouncehandleDataChange, prefDate, setPrefDate]
  );

  useEffect(() => {
    const start: Date = new Date(startDate);
    const end: Date = new Date(endDate);

    const dates: ButtonDates[] = [];
    const dayDifference = Math.floor(
      (end.getTime() - start.getTime()) / (24 * 60 * 60 * 1000)
    );

    if (dayDifference <= 7) {
      let dayCount = 0;

      while (start <= end && dayCount < 7) {
        dates.push({ date: new Date(start), week: moment(start).isoWeek() });
        start.setDate(start.getDate() + 1);
        dayCount++;
      }
    } else {
      return;
    }

    setButtonDates(dates);

    if (
      Days[dates[0]?.date.getDay()] === Days[6] &&
      Days[dates[1]?.date.getDay()] === Days[1]
    ) {
      const momentStartDate: moment.Moment = moment(
        dates[0]?.date,
        "YYYY-MM-DD"
      );

      const calculatedWeekNumber: number = momentStartDate.isoWeek();

      setSunday(calculatedWeekNumber);
    } else {
      const momentStartDate: moment.Moment = moment(
        dates[1]?.date,
        "YYYY-MM-DD"
      );

      const calculatedWeekNumber: number = momentStartDate.isoWeek();

      setSunday(calculatedWeekNumber);
    }
  }, [startDate, endDate, sunday]);

  useEffect(() => {
    buttonDatesRef.current = buttonDates;
    startDateRef.current = startDate;
    endDateRef.current = endDate;
    sundayRef.current = sunday;
  }, [buttonDates, startDate, endDate, sunday]);

  useEffect(() => {
    setColoredAppointments([]);
    const currentDate: Date = new Date();
    const currentWeek: number = moment().isoWeek();
    const sunday: number = sundayRef.current;
    if (selectedOption === "Day") {
      const buttonDates: ButtonDates[] = buttonDatesRef.current;
      if (currentWeek === buttonDates[1]?.week) {
        handleDataChange(
          moment(currentDate).startOf("day").toISOString(),
          moment(currentDate).endOf("day").toISOString()
        );
      } else if (buttonDates.length > 0) {
        setColoredAppointments([]);
        const previousWeekNumber = sunday;
        const sundayDateOfPreviousWeek = moment()
          .week(previousWeekNumber)
          .day("Monday");
        if (sunday === currentWeek) {
          handleDataChange(
            moment(currentDate).startOf("day").toISOString(),
            moment(currentDate).endOf("day").toISOString()
          );
        } else {
          setActiveDay(sundayDateOfPreviousWeek.toString());
          handleDataChange(
            moment(sundayDateOfPreviousWeek).startOf("day").toISOString(),
            moment(sundayDateOfPreviousWeek).endOf("day").toISOString()
          );
        }
      }
    }
  }, [selectedOption, handleDataChange]);

  useEffect(() => {
    const currentWeek: number = moment().isoWeek();
    const currentDate: string = prefDate
      ? moment(prefDate).toISOString()
      : new Date().toString();
    const buttonDates = buttonDatesRef.current;
    if (currentWeek === buttonDates[3]?.week) {
      setActiveDay(currentDate.toString());
    }
    // }
  }, [selectedOption, prefDate]);

  const handleButtonClick = (date: Idate) => {
    const currentDateButton = moment(currentDate).startOf("day").toISOString();
    const dateButtonStart = moment(date.date).startOf("day").toISOString();
    const dateButtonEnd = moment(date.date).endOf("day").toISOString();

    debouncehandleDataChange(dateButtonStart, dateButtonEnd);
    setShowAppointmentDetails(false);
    setEventDetail(null);
    setActiveEvent(null);
    setActiveDay(date?.date.toString());
    if (moment(dateButtonStart).isSame(currentDateButton)) {
      setcurrentDay(true);
    } else {
      setcurrentDay(false);
    }
  };

  const handleTodayButtonClick = useCallback(() => {
    const currentSelectedOption = selectedOption;
    if (currentSelectedOption === "Day") {
      const currentDate: Date = new Date();
      if (new Date(activeDay).getDate() !== currentDate.getDate()) {
        handleDataChange(
          moment(currentDate).startOf("day").toISOString(),
          moment(currentDate).endOf("day").toISOString()
        );
        setActiveDay(new Date().toString());
      }
    }
    setcurrentDay(true);
  }, [selectedOption, handleDataChange, activeDay]);

  useEffect(() => {
    const todayButton = document.querySelector(".fc-today-button");
    if (todayButton) {
      todayButton.addEventListener("click", handleTodayButtonClick);
    }

    return () => {
      if (todayButton) {
        todayButton.removeEventListener("click", handleTodayButtonClick);
      }
    };
  }, [selectedOption, handleTodayButtonClick]);

  const updateMonthYear = (date: Date) => {
    return moment(date).format("MMMM YYYY");
  };

  const getMonthAndYear = (): any => {
    const [firstMonth, firstYear] = currentMonthYear[0].split(" ");
    const [secondMonth, secondYear] = currentMonthYear[1].split(" ");

    if (firstYear === secondYear)
      return `${firstMonth} - ${secondMonth} ${firstYear}`;

    return `${firstMonth} ${firstYear} - ${secondMonth} ${secondYear}`;
  };

  console.log(activeDay, "[client calenderview] active day");

  return (
    <>
      <div className="client-home-wrapper">
        <div className="year-and-month-calendar">
          <p className="year-and-month-calendar-context">
            {typeof currentMonthYear === "string"
              ? currentMonthYear
              : getMonthAndYear()}
          </p>
        </div>
        <div ref={calendarRef} className="calender-view">
          <div className="control-tool-bar">
            <div className="view-picker v-center">
              <div className="dropdown-class">
                <FontAwesomeIcon className="icon-back" icon={faCalendarWeek} />
                <select
                  className="dropdown-select"
                  value={selectedOption}
                  onChange={handleSelect}
                  disabled={softloading}
                >
                  {calenderViewOption.map((option, index) => (
                    <option
                      className="dropdown-option"
                      key={index}
                      value={option.value}
                    >
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div
            className={
              selectedOption === "Day"
                ? "disable-cal-control"
                : "enable-cal-control"
            }
          >
            <div
              className={
                selectedOption === "Day" ? "special-class" : "normal-class"
              }
            >
              <FullCalendar
                ref={calendarRefCustom as React.MutableRefObject<FullCalendar>}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                allDaySlot={false}
                initialView="timeGridCustomView"
                views={{
                  timeGridCustomView: {
                    type: "timeGrid",
                    duration:
                      selectedOption === "Three Days"
                        ? { days: 3 }
                        : { weeks: 1 },
                  },
                }}
                slotMinTime="07:00:00"
                slotMaxTime="22:00:00"
                events={coloredAppointments}
                eventClick={handleEventClick}
                slotEventOverlap={false}
                eventContent={handleEventContent}
                eventDidMount={handleEventDidMount}
                headerToolbar={{
                  left: "prev",
                  center: "title today",
                  right: "next",
                }}
                datesSet={handlePlannerDateViewChange}
                initialDate={refdate1}
              />
            </div>
          </div>
          {selectedOption === calenderViewOption[2].value ? (
            <div
              className={
                isMobile
                  ? "dayAppointements-parent"
                  : "dayAppointements-parent-desktop"
              }
            >
              <div
                className="dayAppointments"
                style={{
                  cursor: softloading ? "not-allowed" : "auto",
                }}
              >
                <div>
                  <button
                    onClick={handlePrevButtonClick}
                    className="custom-nav-control-button"
                  >
                    <FontAwesomeIcon className="icon-back" icon={faAngleLeft} />
                  </button>
                </div>
                {buttonDates.map((date, index) => {
                  return (
                    <div
                      className={`dayAppointments-1 ${
                        moment(activeDay, [
                          "YYYY-MM-DDTHH:mm:ss.SSS[Z]", // Format for scenario 2
                          "ddd MMM DD YYYY", // Format for scenario 1
                        ]).isValid() &&
                        moment(activeDay).format("YYYY-MM-DD") ===
                          moment(date.date).startOf("day").format("YYYY-MM-DD")
                          ? "active"
                          : ""
                      }`}
                      style={{
                        pointerEvents: softloading ? "none" : "auto",
                      }}
                      key={index}
                    >
                      <div
                        key={index}
                        onClick={() => handleButtonClick(date)}
                        className="dayAppointments-group"
                      >
                        <div className="dayAppointments-2">
                          {Days[date.date.getDay()]}
                        </div>
                        <div className="dayAppointments-3">
                          {date.date.getDate()}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div>
                  <button
                    onClick={handleNextButtonClick}
                    className="custom-nav-control-button"
                  >
                    <FontAwesomeIcon
                      className="icon-back"
                      icon={faAngleRight}
                    />
                  </button>
                </div>
              </div>

              <div className="other-controls v-center flx-end">
                <button
                  onClick={navigateToCurrentDay}
                  className="fc-current-today"
                  disabled={currentDay}
                >
                  <span>Today</span>
                </button>
              </div>

              {/* <button onClick={handleNextButtonClick}>testing button cc</button> */}

              {softloading ? (
                <div className="no-appointments">
                  <div className="spinner-calender-view">
                    <SpinnerV2 />
                  </div>
                  <p className="no-appointments-1">
                    Loading appointments for the day . . .
                  </p>
                </div>
              ) : (
                <div className="appointment-list">
                  <span className="global-content-header">
                    <span>Appointments ({coloredAppointments.length})</span>
                    {selectedOption === calenderViewOption[2].value &&
                      activeDay && (
                        <span className="global-day">
                          {moment(activeDay).format("dddd")}
                        </span>
                      )}
                  </span>
                  {!coloredAppointments.length && (
                    <span className="block-header smaller-font">
                      No appointments on this day
                    </span>
                  )}
                  <>
                    {coloredAppointments
                      ?.slice(0, 4)
                      .map((appointment: any, index: number) => {
                        return (
                          <>
                            <div key={index}>
                              <ClientAppointmentBar
                                key={index}
                                appointment={appointment}
                                patientClinic={patientClinic}
                                clientClinicData={clientClinicData}
                                activeDay={activeDay.toString()}
                                path={path}
                              ></ClientAppointmentBar>
                            </div>
                          </>
                        );
                      })}
                  </>
                </div>
              )}
            </div>
          ) : (
            ""
          )}
          <div className="w-100 h-center see-all-appts">
            <div onClick={seeAllAppts}>
              <span>See all appointments</span>
            </div>
          </div>
          {showAppointmentDetails && (
            <AppointmentDetails
              show={showAppointmentDetails}
              onClose={() => setShowAppointmentDetails(false)}
              event={eventDetail}
              clickPosition={clickPosition}
              setActiveEvent={setActiveEvent}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(CalendarView);
