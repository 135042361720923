import { myContext } from "features/Hooks/useContext";
import { appointments } from "model/calender/appointments";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  //   useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AddDays, DiffMinute } from "./clientReschedule.helper";
import { clientRescheduleCall } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsFunction";
import { ApiCalls } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsApi";
import { useAuth0 } from "react-auth0-spa";
import { IAddress } from "model/appointments";
import { Dialog } from "@mui/material";
import { Button } from "antd";
import X from "assets/X.svg";
import Spinner from "features/Appointments/Spinner/spinner";
import "./clientReschedule.scss";

interface ContextType {
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  setPrefDate: React.Dispatch<React.SetStateAction<string>>;
  validateUrl: boolean;
}

interface LocationState {
  activeDay: string;
  appointment: appointments;
  path: string;
}

export const ClientRescheduleFlow: React.FC<{}> = () => {
  const { getTokenSilently } = useAuth0();
  const { state, setState, validateUrl } = useContext<ContextType>(myContext);
  //   const [redirect, setRedirect] = useState(false);

  const redirectRef = useRef(false);

  const location = useLocation<LocationState>();
  const history = useHistory();
  const { appointment, activeDay, path } = location.state;

  const { error, rcSuggestions } = state;

  console.log(rcSuggestions, "[reschedule Flow] rcSuggestions");

  console.log(redirectRef, "[reschedule redirect] client");

  const duration = DiffMinute(appointment.start, appointment.end);

  const startTime = AddDays(appointment.startAt, 1).startOf('day').utc().toISOString();

  const endTime = AddDays(appointment.startAt, 14).endOf('day').utc().toISOString();

  console.log(endTime, "[reschedule] End Time");

  let address: any;

  if (appointment?.address !== null && appointment?.address !== undefined) {
    if (appointment?.location_type === "Telehealth") {
      address = appointment?.link;
    } else {
      appointment?.location_type === "In Clinic"
        ? (address = Object.entries(appointment?.address as IAddress)
            .filter(
              ([key, value]) =>
                value !== null &&
                value !== undefined &&
                value !== "" &&
                key !== "addressLine2"
            )
            .map(([key, value]) => key !== null && `${value}`)
            .join(", "))
        : (address = Object.entries(appointment?.address as IAddress)
            .filter(
              ([key, value]) =>
                key !== null &&
                value !== null &&
                value !== undefined &&
                value !== "" &&
                key !== "name" &&
                key !== "country" &&
                key !== "id"
            )
            .map(([key, value]) => key !== null && `${value}`)
            .join(", "));
    }
  } else {
    address = "N/A";
  }

  const addressData =
    appointment?.location_type === "Telehealth" ? null : address;

  const payload = useMemo(
    () => ({
      eventTypeName: appointment?.parent_type_name,
      clientId: appointment?.client_id,
      appointmentTypeId:
        appointment?.parent_type_name === "ABA"
          ? appointment?.subtype_id
          : appointment?.type_id,
      apptSubTypeId:
        appointment?.parent_type_name === "ABA" ? 0 : appointment?.subtype_id,
      startTime: startTime,
      endTime: endTime,
      providerId: appointment?.provider_id,
      locationType: appointment?.location_type,
      eventId: appointment?.id,
      duration: duration,
      makeupSessions: true,
      eventStatus: "scheduled",
      clientClinicId: appointment?.clinic?.id,
      telehealthLink: appointment?.link,
      locationCategory: appointment?.location_category || "",
      address: addressData,
    }),
    [
      startTime,
      addressData,
      appointment.client_id,
      appointment.type_name,
      appointment.type_id,
      appointment.subtype_id,
      endTime,
      appointment.provider_id,
      appointment.location_type,
      appointment.id,
      duration,
      appointment.clinic.id,
      appointment.link,
      appointment.location_category,
    ]
  );

  const handleRcSuggestions = useCallback(async () => {
    setState((prevData: any) => ({ ...prevData, error: false }));
    try {
      const response = await clientRescheduleCall(
        ApiCalls.rcSuggestions,
        payload,
        getTokenSilently
      );

      if (response && response.data) {
        setState((prevState: any) => ({
          ...prevState,
          rcSuggestions: response.data?.sameProviderMakeupSessions,
        }));
        if (redirectRef.current === false)
          history.push({
            pathname: "/client-reschedule-appointment",
            state: {
              appointment: appointment,
              activeDay: activeDay,
              path: path,
              payload: payload,
            },
          });
        redirectRef.current = true;
      } else {
        console.error("Error: No data received from API response");
      }
    } catch (err) {
      setState((prevData: any) => ({ ...prevData, error: true }));
      console.error("Error in API call:", err);
    }
  }, [
    location.pathname,
    getTokenSilently,
    payload,
    setState,
    activeDay,
    appointment,
    history,
    path,
  ]);

  useEffect(() => {
    handleRcSuggestions();
  }, [handleRcSuggestions]);

  const handleClose = () => {
    setState((prevData: any) => ({ ...prevData, error: false }));
    history.push({
      pathname: "/appointments",
    });
  };

  useEffect(() => {
    validateUrl && history.replace("/appointments");
  }, [validateUrl, history]);

  return (
    <div className="client-flow-main-container">
      {error === true ? (
        <Dialog
          open={error}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="dialogue-reload"
        >
          <div className="client-reschedule-dialogue-flow-reload-pop-up">
            <div className="client-dialogue-X-img">
              <img src={X} alt="Loading" onClick={handleClose} />
            </div>
            <div className="client-dialogue-Heading">
              Something went wrong. Please try again in a moment.
            </div>
            <div className="client-button">
              <Button className="Warning-button" onClick={handleRcSuggestions}>
                Try again
              </Button>
            </div>
          </div>
        </Dialog>
      ) : (
        <>
          <Spinner />
          <span className="client-reschedule-spinner-sub-heading">
            {" "}
            Please wait while we are retrieving new dates and timings for you.
          </span>
        </>
      )}
    </div>
  );
};
