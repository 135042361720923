export const crStatusSocketDevUrl =
  "wss://zkehys76d9.execute-api.us-east-1.amazonaws.com/dev";
export const crStatusUatUrl =
  "wss://l2qcmu02i4.execute-api.us-east-1.amazonaws.com/uat/";
export const crStatusProdUrl =
  "wss://guh86k3xc7.execute-api.us-east-1.amazonaws.com/prod/";

export const getSocketUrl = (environment: string) => {
  if (environment === "local") {
    return crStatusSocketDevUrl;
  } else if (environment === "dev") {
    return crStatusSocketDevUrl;
  } else if (environment === "test") {
    return crStatusUatUrl;
  } else if (environment === "uat") {
    return crStatusUatUrl;
  } else {
    return crStatusProdUrl;
  }
};
