export const default_Dropdown = "Select Reschedule Reason";

export interface CustomSelectOption {
  key?: string;
  label?: string;
  value?: number;
}

export const ClientRescheduleDropdown: CustomSelectOption[] = [
  {
    value: 1,
    label: "Family Emergency",
    key: "canr16", //Provider Cancel: Notice
  },
  {
    value: 2,
    label: "Patient Sick",
    key: "canr28", //Patient Cancel One Time: Sick
  },
  {
    value: 3,
    label: "Schedule Conflict",
    key: "canr12", //Patient Cancel: One time
  },
  {
    value: 4,
    label: "Vacation",
    key: "canr14", //Patient Cancel: Vacation
  },
  {
    value: 5,
    label: "Other",
    key: "canr12", //Patient Cancel: One time
  },
];
