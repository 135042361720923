import React from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ReviewProviderDayWrapper } from "./style";
import moment from "moment";
import { useAuth0 } from "react-auth0-spa";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "features/Appointments/Spinner/spinner";
import { confirmDayCall } from "./AllAppointmentsFunction";
import { ApiCalls } from "./AllAppointmentsApi";
import Popup from "sharedComponents/reactHookComponents/popup/popup";

interface LocationState {
  providerId: number; // Replace with the actual data type
  providerName: string;
  providerEmail: string;
  providerClinicState: string;
  hasDurationMoreThan5Hours: boolean;
  shouldAskRestBreakQuestion: boolean;
  date: string; // Replace with the actual data type
}

const ReviewProviderDay: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { getTokenSilently } = useAuth0();
  const {
    providerId,
    providerName,
    providerEmail,
    providerClinicState,
    date,
    hasDurationMoreThan5Hours,
    shouldAskRestBreakQuestion,
  } = location.state;
  const [softloading, setsoftloading] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [disableButton, setDisableBtn] = useState<boolean>(true);
  const [backendError, setBackendError] = useState(false);

  const dateString = moment(new Date(date)).format("MMMM D, YYYY");

  const payloadDate = moment(new Date(date)).startOf("day").toISOString();
  const [mealBreaks, setMealBreaks] = useState<boolean | null>(null);
  const [restBreaks, setRestBreaks] = useState<boolean | null>(null);
  const [showMealBreakWarning, setMealBreakWarning] = useState<boolean>(false);

  const homeRedirection = () => {
    history.push({
      pathname: "/providerappointments",
      state: {
        prefStartDate: date,
      },
    });
  };

  const handleCheck = () => {
    setChecked((prevChecked) => {
      const newChecked = !prevChecked;
      if (newChecked) {
        if (providerClinicState === "CA") {
          if (
            shouldAskRestBreakQuestion &&
            hasDurationMoreThan5Hours &&
            (mealBreaks === null || restBreaks === null)
          )
            setDisableBtn(true);
          else if (shouldAskRestBreakQuestion && restBreaks === null)
            setDisableBtn(true);
          else if (hasDurationMoreThan5Hours && mealBreaks === null)
            setDisableBtn(true);
          else setDisableBtn(false);
        } else {
          setDisableBtn(false);
        }
      } else {
        setDisableBtn(true);
      }
      return newChecked;
    });
  };

  const handleMealBreaksChange = (value: boolean) => {
    setMealBreaks(() => {
      const newMealBreaks = value;

      newMealBreaks ? setMealBreakWarning(false) : setMealBreakWarning(true);

      if (checked && restBreaks !== null && newMealBreaks !== null) {
        setDisableBtn(false);
      } else if (
        checked &&
        shouldAskRestBreakQuestion == false &&
        newMealBreaks !== null
      ) {
        setDisableBtn(false);
      } else {
        setDisableBtn(true);
      }

      return newMealBreaks;
    });
  };

  const handleRestBreaksChange = (value: boolean) => {
    setRestBreaks(() => {
      const newRestBreaks = value;

      if (checked && mealBreaks !== null && newRestBreaks !== null) {
        setDisableBtn(false);
      } else if (
        checked &&
        hasDurationMoreThan5Hours == false &&
        newRestBreaks !== null
      ) {
        setDisableBtn(false);
      } else {
        setDisableBtn(true);
      }

      return newRestBreaks;
    });
  };

  const payloadToSend = {
    date: payloadDate,
    providerId: providerId,
    providerName: providerName,
    providerEmail: providerEmail,
    mealBreaks: mealBreaks,
    restBreaks: restBreaks,
  };

  async function confirmDay(payload: any) {
    setsoftloading(true);
    setBackendError(false);

    try {
      await confirmDayCall(ApiCalls.ConfirmDayCall, payload, getTokenSilently);
      setsoftloading(false);
      homeRedirection();
    } catch (error) {
      setsoftloading(false);
      setBackendError(true);
      console.log("[ReviewProviderDay] confirm call error", error);
    }
  }

  const submitData = () => {
    confirmDay(payloadToSend);
  };

  const handleClosePopUp = () => {
    setBackendError(false);
    history.push({
      pathname: "/providerappointments",
      state: {
        prefStartDate: date,
      },
    });
  };

  return (
    <ReviewProviderDayWrapper>
      {backendError && (
        <>
          <Popup
            isOpen={backendError}
            onClose={handleClosePopUp}
            heading={"Failed to Confirm the Day"}
            paragraph={
              "Sorry, Something went wrong while confirming your day. Most probably due to server error. You can try again after few minutes."
            }
          />
        </>
      )}
      <div className="wrapper-container">
        <div className="header-div">
          <div className="back-to h-end v-center">
            <button onClick={homeRedirection}>
              <FontAwesomeIcon className="icon-back" icon={faChevronLeft} />
            </button>
          </div>
          <div className="page-desc h-center v-center">
            <span>Confirm Appointments</span>
          </div>
          <div className="back-to"></div>
        </div>
        {softloading && (
          <div className="loading-io w-100 d-flex fx-column gutter-top-50">
            <div className="load-div w-100 d-flex h-center">
              <Spinner />
            </div>
            <div className="title-div h-center gutter-top-20">
              <span>Confirming Appointments</span>
            </div>
          </div>
        )}
        {!softloading && (
          <div className="info-content-div padding-lr-7 w-100">
            <div className="attestation">
              <div className="check-wrapper">
                <input
                  type="checkbox"
                  onChange={handleCheck}
                  checked={checked}
                />
              </div>
              <div className="text-wrapper d-contents">
                <span>
                  I attest that all appointments on {dateString} are accurate.
                </span>
              </div>
            </div>
            {providerClinicState === "CA" && (
              <div className="caAttestation">
                {hasDurationMoreThan5Hours && (
                  <div>
                    <div className="text-wrapper">
                      <span>
                        Did you have provided the reasonable opportunity to take
                        your uninterrupted meal period(s) today, according to
                        the Cortica Meal and Rest Break policy? (Note: If you
                        had a reasonable opportunity to take your uninterrupted
                        meal periods but voluntarily chose to skip them or start
                        them after 5 hours of work, answer yes.)
                      </span>
                    </div>
                    <div
                      className="radio-wrapper"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        height: "60px",
                        marginTop: "10px",
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          name="mealBreaks"
                          value="Yes"
                          onChange={() => handleMealBreaksChange(true)}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="mealBreaks"
                          value="No"
                          onChange={() => handleMealBreaksChange(false)}
                        />
                        No
                      </label>
                    </div>
                    {showMealBreakWarning && (
                      <div className="d-flex meal-warning m-bottom-20">
                        <div className="warning-icon fixed-width-25">
                          <FontAwesomeIcon
                            className="icon-back"
                            icon={faExclamationCircle}
                          />
                        </div>
                        <div className="warning-msg flx-grow-1">
                          <span className="d-block">
                            You've answered that you have not received your meal
                            period(s) according to Cortica policy. If this is
                            accurate, then your schedule should reflect that you
                            either:
                          </span>
                          <span className="d-block">
                            1. Didn't take a meal break by your fifth hour of
                            work, or
                          </span>
                          <span className="d-block">
                            2. Your meal break was not an uninterrupted period
                            of 30+ minutes
                          </span>
                          <span className="d-block">
                            Please update your schedule to reflect time worked.
                            If one of the above conditions is met and accurately
                            reflects your time worked today, a meal penalty will
                            be added to your pay.
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div style={{ marginTop: "10px" }}></div>
                {shouldAskRestBreakQuestion && (
                  <div>
                    <div className="text-wrapper">
                      <span>
                        Did you have the reasonable opportunity to take your
                        uninterrupted paid rest break(s) today, according to the
                        Cortica Meal and Rest Break policy? (Note: If you had a
                        reasonable opportunity to take your uninterrupted breaks
                        but voluntarily chose to skip them, answer yes.){" "}
                      </span>
                    </div>
                    <div
                      className="radio-wrapper"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        height: "60px",
                        marginTop: "10px",
                      }}
                    >
                      <label>
                        <input
                          type="radio"
                          name="restBreaks"
                          value="Yes"
                          onChange={() => handleRestBreaksChange(true)}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="restBreaks"
                          value="No"
                          onChange={() => handleRestBreaksChange(false)}
                        />
                        No
                      </label>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="submit-button">
              <button disabled={disableButton} onClick={submitData}>
                Confirm
              </button>
            </div>
          </div>
        )}
      </div>
    </ReviewProviderDayWrapper>
  );
};

export default React.memo(ReviewProviderDay);
