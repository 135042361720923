import React, { useState, useCallback, useEffect } from "react";
import { useAuth0 } from "react-auth0-spa";
import axios from "axios";
import Loader from "features/Loader/Loader";
import { getFromCache, cache } from "./cache.js";
import moment from "moment";
import {
  appointments,
  IAppointments,
  PatientClinic,
} from "model/calender/appointments.js";
import CalenderView from "./Calendar/CalenderView";
import SubHeader from "features/SubHeader/Subheader";
import Failure from "./Failure/Failure";
import { isMobile } from "react-device-detect";
import ClientActivities from "./Calendar/Supporting-Components/ClientActivities";
import ClientFAQ from "./Calendar/Supporting-Components/ClientFAQ";
import { clientClinics } from "./biportal/AppointmentForms/AllAppointmentsFunction";
import { ApiCalls } from "./biportal/AppointmentForms/AllAppointmentsApi";
import "./Appointments.scss";
import SpinnerV2 from "features/Appointments/spinnerV2/spinnerV2";
// import { useLocation } from "react-router-dom";

const CACHED_APPOINTMENTS_KEY: String = "appointments";
// interface LocationState {
//   prevDate: any;
// }

const Appointment: React.FC<{}> = () => {
  const { loading, user, getTokenSilently } = useAuth0();
  // const location = useLocation<LocationState>();
  // const refDate = location.state?.prevDate || undefined;

  // console.log(refDate, "refDate in the appointments");

  const [appointments, setAppointments] = useState<appointments[]>(
    getFromCache(CACHED_APPOINTMENTS_KEY) || []
  );
  const [backendError, setBackendError] = useState<Boolean>(false);
  const [clinicBackendError, setClinicBackendError] = useState<Boolean>(false);
  const [softloading, setsoftloading] = useState<boolean>(false);
  const [patientClinic, setPatientClinic] = useState<number[]>([]);
  const [clientClinicData, setClientClinicData] = useState<any>();
  const [hasComponentMounted, setComponentMounted] = useState<boolean>(true);

  const [noApptsRecord, setApptsRecords] = useState<boolean>(false);
  console.log("user data logged", user);

  const showClientActivities = false;

  async function getAppointments(startDate: String, endDate: String) {
    const accessToken = await getTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const payLoad = {
      user_id: user.sub,
      isProvider: false,
      startDate: startDate,
      endDate: endDate,
      email: user.email,
    };
    // TODO: add a try catch for the get request @sushma from Scott
    const apiUrl =
      process.env.REACT_APP_STAGE === "local"
        ? `http://localhost:8000/appointments`
        : `${process.env.REACT_APP_BACKEND_API}/appointments`;

    setsoftloading(true);

    axios
      .post(apiUrl, payLoad, config)
      .then((resp) => {
        const clinicIds = resp.data.map((element: any) => element.clinicId);
        setClientClinicData(clinicIds);
        // As format returned is [{ clientId, appointments }]
        console.log("[appts tsx] resp", resp);
        let results: appointments[] = [];
        resp.data.forEach((element: IAppointments, patientIndex: number) => {
          setComponentMounted(false);
          if (element.count === 0) {
            setApptsRecords(true);
          }
          if (element.appointments && Array.isArray(element.appointments)) {
            results.push(
              ...element.appointments.map((appointment) => ({
                ...appointment,
                title: appointment.type_name,
                start: appointment.startAt,
                end: appointment.endAt,
                client: appointment.client_name,
                provider: appointment.provider_name,
                patientIndex,
              }))
            );
          }
        });
        results.sort(
          (a: appointments, b: appointments) =>
            new Date(a.startAt).getTime() - new Date(b.startAt).getTime()
        );
        setAppointments(results);
        cache(CACHED_APPOINTMENTS_KEY, results);
        setBackendError(false);
        setsoftloading(false);
      })
      .catch((err) => {
        console.log(err.response);
        setBackendError(true);
        setsoftloading(false);
      });
  }

  useEffect(() => {
    const getclinics = async () => {
      try {
        let response: any;
        response = await clientClinics(
          ApiCalls.patientClinics,
          getTokenSilently
        );
        if (response) {
          const clinicArrayData = JSON.parse(response?.data);
          setPatientClinic(
            clinicArrayData?.Clinic_Array_Data.filter(
              (clinic: PatientClinic) => clinic.isActive
            ).map((clinic: PatientClinic) => clinic.clinic)
          );
        }
      } catch (error) {
        setClinicBackendError(true);
        console.error("Error fetching clinics:", error);
      }
    };

    getclinics();
  }, [getTokenSilently]);

  const BackEndError = !backendError && !clinicBackendError ? false : true;

  const getAppointmentsCallback = useCallback(getAppointments, [
    getTokenSilently,
    user.email,
    user.sub,
  ]);

  const handleDataChange = useCallback(
    (startStr: string, endStr: string) => {
      getAppointmentsCallback(startStr, endStr);
    },
    [getAppointmentsCallback]
  );

  useEffect(() => {
    const currentDate: Date = new Date();
    handleDataChange(
      moment(currentDate).startOf("day").toISOString(),
      moment(currentDate).endOf("day").toISOString()
    );
  }, []);

  useEffect(() => {
    const ismobile = isMobile;
    if (ismobile) {
      const currentDate: Date = new Date();
      getAppointmentsCallback(
        moment(currentDate).startOf("day").toISOString(),
        moment(currentDate).endOf("day").toISOString()
      );
    }
  }, [getAppointmentsCallback]);

  if (loading || !user) {
    return <Loader />;
  }

  const getAppointmentsTable = (appointments: appointments[]) => (
    <CalenderView
      appointments={appointments}
      handleDataChange={handleDataChange}
      softloading={softloading}
      patientClinic={patientClinic}
      clientClinicData={clientClinicData}
      // refDate={refDate}
    />
  );

  const reloadAppointments = () => {
    window.location.reload();
  };

  let client = user?.name ? user.name : user?.nickname;

  return (
    <>
      <SubHeader client={client} isLoading={softloading} />
      {BackEndError ? (
        <Failure refresh={reloadAppointments} />
      ) : noApptsRecord ? (
        <div className="no-records">
          <span className="global-content-header">Appointments (0)</span>
          <p className="para-sm">
            We don’t have any appointments for you at this time. We’d like to
            get you scheduled.
          </p>
        </div>
      ) : !hasComponentMounted ? (
        getAppointmentsTable(appointments)
      ) : hasComponentMounted ? (
        <>
          <div className="no-appointments">
            <div className="spinner-calender-view">
              <SpinnerV2 />
            </div>
            <p className="no-appointments-1">Loading Appointments Data</p>
          </div>
        </>
      ) : (
        <></>
      )}

      {showClientActivities && <ClientActivities />}

      <ClientFAQ />
    </>
  );
};

export default React.memo(Appointment);
