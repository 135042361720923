import React, { useCallback, useEffect, useState } from "react";
import "./availabilityCards.scss";
import Trash from "assets/Trash.svg";
import TrashDisable from "assets/Trash-Disable.svg";
import Plus from "assets/Plus.svg";
import dayjs from "dayjs";
import { TimePicker, Checkbox } from "antd";
import {
  Availability_ADD_SCHEMA,
  Inclinic_Checked,
  Home_Checked,
} from "utils/PatientPortal/Validators/AvailabilityValidation";

export const AvailabilityCard = ({
  heading,
  locationData,
  locations,
  forwardedRef,
  dayOfWeek,
}) => {
  const [validations, setValidations] = useState([]);
  const [checkBoxValidation, setCheckBoxValidation] = useState([]);
  const [startTimestatus, setStartTimeStatus] = useState([]);
  const format = "hh:mm a";

  const handleAddLocation = () => {
    locations([
      ...locationData,
      {
        dayOfWeek: dayOfWeek,
        startTime: "",
        endTime: "",
        locationInput: "",
      },
    ]);
  };
  const handleDeleteLocation = (index) => {
    const updatedLocations = locationData.filter((_, i) => i !== index);
    locations(updatedLocations);

    if (validations) {
      const updatedValidation = validations.filter((_, i) => i !== index);
      setValidations(updatedValidation);
    }
  };

  const handleInputChange = (index, key, value) => {
    const updatedLocations = [...locationData];
    updatedLocations[index][key] = value;
    locations(updatedLocations);
  };

  const handleRadioChange = (index, option) => {
    const validationArray = locationData.map((location, index) => {
      return { validation: Availability_ADD_SCHEMA.successfullValidation };
    });
    setCheckBoxValidation((prevStatus) => ({
      ...prevStatus,
      [index]: validationArray[index],
    }));
    const updatedLocations = [...locationData];
    updatedLocations[index].locationInput = option;
    locations(updatedLocations);
  };

  const handleStartTimeChange = (time, timeString, index) => {
    const validationArray = locationData.map((location, index) => {
      return { validation: Availability_ADD_SCHEMA.successfullValidation };
    });
    setStartTimeStatus((prevStatus) => ({
      ...prevStatus,
      [index]: validationArray[index],
    }));
    const momentTime = dayjs(timeString, format);

    if (!locationData[index].endTime) {
      const endTime = momentTime.add(15, "minute");
      handleInputChange(index, "endTime", endTime);
    }

    handleInputChange(index, "startTime", momentTime);
  };

  const handleEndTimeChange = (time, timeString, index) => {
    const validationArray = locationData.map((location, index) => {
      return { validation: Availability_ADD_SCHEMA.successfullValidation };
    });
    setStartTimeStatus((prevStatus) => ({
      ...prevStatus,
      [index]: validationArray[index],
    }));
    const momentTime = dayjs(timeString, format);
    handleInputChange(index, "endTime", momentTime);
  };

  const handleValidate = useCallback(() => {
    const validationArray = locationData.map((location, index) => {
      if (
        (location.startTime === "" ||
          location.startTime === undefined ||
          location.startTime === null) &&
        (location.endTime === "" ||
          location.endTime === undefined ||
          location.endTime === null) &&
        (location.locationInput === "" ||
          location.locationInput === undefined ||
          location.locationInput === null)
      ) {
        return { validation: Availability_ADD_SCHEMA.allFields };
      } else if (
        location.locationInput === "" ||
        location.locationInput === null
      ) {
        return { validation: Availability_ADD_SCHEMA.locationFields };
      } else if (
        location.startTime === "" ||
        location.endTime === "" ||
        location.startTime === null ||
        location.endTime === null
      ) {
        return { validation: Availability_ADD_SCHEMA.timeFieldsStartAndEnd };
      } else if (location.endTime.isBefore(location.startTime)) {
        return { validation: Availability_ADD_SCHEMA.timeFieldEnd };
      } else if (location.endTime.isSame(location.startTime)) {
        return { validation: Availability_ADD_SCHEMA.timeFieldSame };
      }
      return { validation: Availability_ADD_SCHEMA.successfullValidation };
    });

    setValidations(validationArray);
    setCheckBoxValidation(validationArray);
    setStartTimeStatus(validationArray);

    const anyValidationNotEmpty = validationArray.some(
      (val) => val.validation !== ""
    );

    return !anyValidationNotEmpty;
  }, [locationData]);

  useEffect(() => {
    forwardedRef.current = {
      handleValidate: handleValidate,
    };
  }, [handleValidate, forwardedRef]);

  return (
    <div className="Container">
      <span className="Heading">{heading}</span>
      {locationData?.map((location, index) => {
        return (
          <div className="Inner-main-container" key={location.id || index}>
            <div className="Inner-container" key={index}>
              <div className="Inner-main">
                <div className="Inner-text">
                  <span className="Inner-text-Heading">Location</span>
                  <div className="Inner-text-label">
                    <label className="Inner-text-checkbox">
                      <Checkbox
                        type="checkbox"
                        name={`locationRadio_${index}`}
                        value={3}
                        checked={location.locationInput === 3}
                        onChange={() => handleRadioChange(index, 3)}
                        className={
                          checkBoxValidation[index]?.validation ===
                            Availability_ADD_SCHEMA.locationFields ||
                          checkBoxValidation[index]?.validation ===
                            Availability_ADD_SCHEMA.allFields
                            ? "error-checkbox"
                            : ""
                        }
                      >
                        In Clinic
                      </Checkbox>
                    </label>
                    <label className="Inner-text-checkbox">
                      <Checkbox
                        type="checkbox"
                        name={`locationRadio_${index}`}
                        value={5}
                        checked={location.locationInput === 5}
                        onChange={() => handleRadioChange(index, 5)}
                        className={
                          checkBoxValidation[index]?.validation ===
                            Availability_ADD_SCHEMA.locationFields ||
                          checkBoxValidation[index]?.validation ===
                            Availability_ADD_SCHEMA.allFields
                            ? "error-checkbox"
                            : ""
                        }
                      >
                        Home
                      </Checkbox>
                    </label>
                  </div>
                </div>
                <div className="Inner-second-feild">
                  <div className="Inner-text-startTime">
                    <span className="Inner-span-text">Start time</span>
                    <TimePicker
                      value={location.startTime}
                      format={format}
                      onChange={(time, timeString) =>
                        handleStartTimeChange(time, timeString, index)
                      }
                      changeOnScroll
                      needConfirm={true}
                      allowClear={false}
                      minuteStep={15}
                      showNow={false}
                      inputReadOnly={true}
                      status={
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.timeFieldsStartAndEnd ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.TimeFieldWithin ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.timeFieldEnd ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.TimeFieldOverlaps ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.allFields
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                  <div className="Inner-text-startTime">
                    <span className="Inner-span-text-end">End time</span>
                    <TimePicker
                      value={location.endTime}
                      changeOnScroll
                      needConfirm={true}
                      format={format}
                      onChange={(time, timeString) =>
                        handleEndTimeChange(time, timeString, index)
                      }
                      allowClear={false}
                      minuteStep={15}
                      showNow={false}
                      inputReadOnly={true}
                      status={
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.timeFieldsStartAndEnd ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.TimeFieldWithin ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.timeFieldEnd ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.TimeFieldOverlaps ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.allFields
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="Trash-button-main">
                <img
                  className="Trash-button"
                  src={Trash}
                  alt=""
                  onClick={() => handleDeleteLocation(index)}
                />
              </div>
            </div>

            {validations &&
              validations.length > 0 &&
              validations[index]?.validation !== "" && (
                <div className="validation">
                  {validations[index]?.validation}
                </div>
              )}
          </div>
        );
      })}

      <div className="Add-more" onClick={handleAddLocation}>
        <img src={Plus} alt="Loading" className="Add-more-img"></img>
        <span className="Add-more-text">Add more available time</span>
      </div>
    </div>
  );
};

export const EditAvailabilityCard = ({
  heading,
  locationData,
  locations,
  forwardedRef,
  dayOfWeek,
}) => {
  const [validations, setValidations] = useState([]);
  const [checkBoxValidation, setCheckBoxValidation] = useState([]);
  const [startTimestatus, setStartTimeStatus] = useState([]);
  const format = "hh:mm a";

  const handleAddLocation = () => {
    locations([
      ...locationData,
      {
        dayOfWeek: dayOfWeek,
        startTime: "",
        endTime: "",
        locationInput: "",
        isDisabled: false,
      },
    ]);
  };
  const handleDeleteLocation = (index) => {
    const updatedLocations = locationData.filter((_, i) => i !== index);
    locations(updatedLocations);

    if (validations) {
      const updatedValidation = validations.filter((_, i) => i !== index);
      setValidations(updatedValidation);
    }
  };

  const handleInputChange = (index, key, value) => {
    const updatedLocations = [...locationData];
    updatedLocations[index][key] = value;
    locations(updatedLocations);
  };

  const handleRadioChange = (index, option) => {
    const validationArray = locationData.map((location, index) => {
      return { validation: Availability_ADD_SCHEMA.successfullValidation };
    });
    setCheckBoxValidation((prevStatus) => ({
      ...prevStatus,
      [index]: validationArray[index],
    }));
    const updatedLocations = [...locationData];
    updatedLocations[index].locationInput = option;
    locations(updatedLocations);
  };

  const handleStartTimeChange = (time, timeString, index) => {
    const validationArray = locationData.map((location, index) => {
      return { validation: Availability_ADD_SCHEMA.successfullValidation };
    });
    setStartTimeStatus((prevStatus) => ({
      ...prevStatus,
      [index]: validationArray[index],
    }));
    const momentTime = dayjs(timeString, format);

    if (!locationData[index].endTime) {
      const endTime = momentTime.add(15, "minute");
      handleInputChange(index, "endTime", endTime);
    }

    handleInputChange(index, "startTime", momentTime);
  };

  const handleEndTimeChange = (time, timeString, index) => {
    const validationArray = locationData.map((location, index) => {
      return { validation: Availability_ADD_SCHEMA.successfullValidation };
    });
    setStartTimeStatus((prevStatus) => ({
      ...prevStatus,
      [index]: validationArray[index],
    }));
    const momentTime = dayjs(timeString, format);
    handleInputChange(index, "endTime", momentTime);
  };

  const handleValidate = useCallback(() => {
    const validationArray = locationData.map((location, index) => {
      if (
        (location.startTime === "" ||
          location.startTime === undefined ||
          location.startTime === null) &&
        (location.endTime === "" ||
          location.endTime === undefined ||
          location.endTime === null) &&
        (location.locationInput === "" ||
          location.locationInput === undefined ||
          location.locationInput === null)
      ) {
        return { validation: Availability_ADD_SCHEMA.allFields };
      } else if (
        location.locationInput === "" ||
        location.locationInput === null
      ) {
        return { validation: Availability_ADD_SCHEMA.locationFields };
      } else if (
        location.startTime === "" ||
        location.endTime === "" ||
        location.startTime === null ||
        location.endTime === null
      ) {
        return { validation: Availability_ADD_SCHEMA.timeFieldsStartAndEnd };
      } else if (location.endTime.isBefore(location.startTime)) {
        return { validation: Availability_ADD_SCHEMA.timeFieldEnd };
      } else if (location.endTime.isSame(location.startTime)) {
        return { validation: Availability_ADD_SCHEMA.timeFieldSame };
      }

      return { validation: Availability_ADD_SCHEMA.successfullValidation };
    });

    setValidations(validationArray);
    setCheckBoxValidation(validationArray);
    setStartTimeStatus(validationArray);

    const anyValidationNotEmpty = validationArray.some(
      (val) => val.validation !== ""
    );

    return !anyValidationNotEmpty;
  }, [locationData]);

  useEffect(() => {
    forwardedRef.current = {
      handleValidate: handleValidate,
    };
  }, [handleValidate, forwardedRef]);

  return (
    <div className="Container">
      <span className="Heading">{heading}</span>
      {locationData?.map((location, index) => {
        return (
          <div className="Inner-main-container" key={location.id || index}>
            <div className="Inner-container" key={index}>
              <div className="Inner-main">
                <div className="Inner-text">
                  <span className="Inner-text-Heading">Location</span>
                  <div className="Inner-text-label">
                    <label className="Inner-text-checkbox">
                      <Checkbox
                        type="checkbox"
                        name={`locationRadio_${index}`}
                        value={3}
                        checked={
                          Inclinic_Checked.includes(location.location) ||
                          Inclinic_Checked.includes(location.locationInput)
                        }
                        onChange={() => handleRadioChange(index, 3)}
                        className={
                          checkBoxValidation[index]?.validation ===
                            Availability_ADD_SCHEMA.locationFields ||
                          checkBoxValidation[index]?.validation ===
                            Availability_ADD_SCHEMA.allFields
                            ? "error-checkbox"
                            : ""
                        }
                        disabled={location.isDisabled}
                      >
                        In Clinic
                      </Checkbox>
                    </label>
                    <label className="Inner-text-checkbox">
                      <Checkbox
                        type="checkbox"
                        name={`locationRadio_${index}`}
                        value={5}
                        checked={
                          Home_Checked.includes(location.location) ||
                          Home_Checked.includes(location.locationInput)
                        }
                        onChange={() => handleRadioChange(index, 5)}
                        className={
                          checkBoxValidation[index]?.validation ===
                            Availability_ADD_SCHEMA.locationFields ||
                          checkBoxValidation[index]?.validation ===
                            Availability_ADD_SCHEMA.allFields
                            ? "error-checkbox"
                            : ""
                        }
                        disabled={location.isDisabled}
                      >
                        Home
                      </Checkbox>
                    </label>
                  </div>
                </div>
                <div className="Inner-second-feild">
                  <div className="Inner-text-startTime">
                    <span className="Inner-span-text">Start time</span>
                    <TimePicker
                      value={location.startTime}
                      format={format}
                      onChange={(time, timeString) =>
                        handleStartTimeChange(time, timeString, index)
                      }
                      changeOnScroll
                      needConfirm={true}
                      allowClear={false}
                      minuteStep={15}
                      showNow={false}
                      inputReadOnly={true}
                      status={
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.timeFieldsStartAndEnd ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.TimeFieldWithin ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.timeFieldEnd ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.TimeFieldOverlaps ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.allFields
                          ? "error"
                          : ""
                      }
                      disabled={location.isDisabled}
                    />
                  </div>
                  <div className="Inner-text-startTime">
                    <span className="Inner-span-text-end">End time</span>
                    <TimePicker
                      value={location.endTime}
                      changeOnScroll
                      needConfirm={true}
                      format={format}
                      onChange={(time, timeString) =>
                        handleEndTimeChange(time, timeString, index)
                      }
                      allowClear={false}
                      minuteStep={15}
                      showNow={false}
                      inputReadOnly={true}
                      status={
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.timeFieldsStartAndEnd ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.TimeFieldWithin ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.timeFieldEnd ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.TimeFieldOverlaps ||
                        startTimestatus[index]?.validation ===
                          Availability_ADD_SCHEMA.allFields
                          ? "error"
                          : ""
                      }
                      disabled={location.isDisabled}
                    />
                  </div>
                </div>
              </div>
              {location.isDisabled ? (
                <div className="Trash-button-main-disable">
                  <img className="Trash-button" src={TrashDisable} alt="" />
                </div>
              ) : (
                <div className="Trash-button-main">
                  <img
                    className="Trash-button"
                    src={Trash}
                    alt=""
                    onClick={() => handleDeleteLocation(index)}
                  />
                </div>
              )}
            </div>

            {validations &&
              validations.length > 0 &&
              validations[index]?.validation !== "" && (
                <div className="validation">
                  {validations[index]?.validation}
                </div>
              )}
          </div>
        );
      })}

      <div className="Add-more" onClick={handleAddLocation}>
        <img src={Plus} alt="Loading" className="Add-more-img"></img>
        <span className="Add-more-text">Add more available time</span>
      </div>
    </div>
  );
};
