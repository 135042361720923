import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  cancelAppointmentPayload,
  getTimeFromIso,
} from "./appointments.helper";
import { ConfirmAppointmentWrapper } from "./style";
import { CANCELLATION_REASONS } from "constant/BiPortalConstants";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faClock,
  faLocationArrow,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import SpinnerV2 from "../../spinnerV2/spinnerV2";
import { useAuth0 } from "react-auth0-spa";
// import axios from "axios";
import { useHistory } from "react-router-dom";
import { Appointment } from "model/appointments";
import { cancelAppointmentCall } from "./AllAppointmentsFunction";
import { ApiCalls } from "./AllAppointmentsApi";
import Popup from "sharedComponents/reactHookComponents/popup/popup";

interface Props {
  providerId: number;
  leadEventId?: string;
  providerClinicId: number;
  providerName: string;
  appointment: Appointment;
}

const ConfirmCancellation: React.FC<Props> = ({
  providerId,
  leadEventId,
  providerClinicId,
  providerName,
  appointment,
}) => {
  const { user } = useAuth0();
  const methods = useFormContext();

  const history = useHistory();
  const { getTokenSilently } = useAuth0();
  const [softloading, setsoftloading] = useState(false);
  const [backendError, setBackendError] = useState(false);

  const cancelReason: string = methods.getValues("cancellationId");
  const note: string = methods.getValues("note");

  const displayCancelReason = CANCELLATION_REASONS.filter((reason) => {
    return reason.value === cancelReason;
  })[0];

  const displayDate = moment(new Date(appointment.start)).format(
    " MMMM DD, YYYY"
  );

  const payload = {
    providerId: providerId,
    providerClinicId: providerClinicId,
    providerName: providerName,
    providerEmail: user.email,
    leadEventId: leadEventId,
    appointmentType: appointment.appointment_type,
    startDate: appointment.start,
    endDate: appointment.end,
    clinicId: appointment.clinic.id,
  };

  const payloadData = cancelAppointmentPayload(
    payload,
    cancelReason,
    displayCancelReason.label,
    appointment,
    note
  );

  const displayTime = getTimeFromIso(appointment.start, appointment.end);

  const homeRedirection = () => {
    history.push({
      pathname: "/providerappointments", // New route
      state: {
        prefStartDate: appointment.start,
        hardCancellation: appointment.id,
      },
    });
  };

  async function cancelAppointment(payload: any) {
    setsoftloading(true);
    setBackendError(false);
    try {
      await cancelAppointmentCall(
        ApiCalls.ConfirmCancelCall,
        payload,
        getTokenSilently
      );
      setsoftloading(false);
      homeRedirection();
    } catch (error) {
      setBackendError(true);
      setsoftloading(false);
      console.log(error, "[Provider] [ApptCancellation] error", error);
    }
  }

  const submitData = () => {
    cancelAppointment(payloadData);
  };

  const handleClosePopUp = () => {
    setBackendError(false);
    history.push({
      pathname: "/providerappointments", // New route
      state: {
        prefStartDate: appointment.start,
        hardCancellation: appointment.id,
      },
    });
  };

  return (
    <ConfirmAppointmentWrapper>
      {backendError && (
        <>
          <Popup
            isOpen={backendError}
            onClose={handleClosePopUp}
            heading={"Sorry, Failed to Cancel the Appointment"}
            paragraph={
              "The cancelation has failed due to server error. But some time the cancelation can still happen. You can check the appointments list in the home screen and perform this operation after few minutes again."
            }
          />
        </>
      )}
      <div className="wrapper-container">
        {softloading && (
          <div className="loading-io w-100 d-flex fx-column gutter-top-50">
            <div className="load-div w-100 d-flex h-center">
              <SpinnerV2 />
            </div>
            <div className="title-div h-center gutter-top-20">
              <span>Cancelling Appointment</span>
            </div>
          </div>
        )}
        {!softloading && (
          <div className="info-content-div w-100">
            <div className="span-block">
              {appointment.appointment_type === "Admin" ? (
                <span>{appointment.title}</span>
              ) : (
                <>
                  <span className="icon-span">
                    <FontAwesomeIcon className="icon-back" icon={faUser} />
                  </span>
                  <span>
                    {appointment?.client_name}, {appointment.title}
                  </span>
                </>
              )}
            </div>
            <div className="span-block">
              <span className="icon-span">
                <FontAwesomeIcon className="icon-back" icon={faCalendar} />
              </span>
              <span>{displayDate}</span>
            </div>
            <div className="span-block">
              <span className="icon-span">
                <FontAwesomeIcon className="icon-back" icon={faClock} />
              </span>
              <span>
                {displayTime.startTime.toLowerCase()} -{" "}
                {displayTime.endTime.toLowerCase()}
              </span>
            </div>
            {appointment.appointment_type !== "Admin" && (
              <div className="span-block">
                <span className="icon-span">
                  <FontAwesomeIcon
                    className="icon-back"
                    icon={faLocationArrow}
                  />
                </span>
                {appointment.location_type !== "Telehealth" ? (
                  <span>
                    {appointment.address?.addressLine1}.{" "}
                    {appointment.address?.city}, {appointment.address?.state},{" "}
                    {appointment.address?.zipCode}
                  </span>
                ) : appointment.link !== "N/A" ? (
                  <span>{appointment.link}</span>
                ) : (
                  <span>{appointment.location_type}</span>
                )}
              </div>
            )}
            <div className="span-block">
              <span>
                <span className="bolder-custom-span">Cancellation Reason:</span>{" "}
                {displayCancelReason?.label}
              </span>
            </div>
            <div className="span-block">
              <span>
                <span className="bolder-custom-span">Note:</span> {note}
              </span>
            </div>
            <div className="submit-button">
              <button onClick={submitData}>Confirm</button>
            </div>
          </div>
        )}
      </div>
    </ConfirmAppointmentWrapper>
  );
};

export default React.memo(ConfirmCancellation);
