import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./editPatientAvailabilityReview.scss";
import { EditAvailabilityCard } from "features/Appointments/AvailiablilityCards/AvailiabilityCards";
import { Button } from "antd";
import { myContext } from "features/Hooks/useContext";
import { useHistory } from "react-router-dom";
import { dayOfWeek } from "./staticAvailabilityData";
import dayjs from "dayjs";
import { patientAvailabilityApptCall } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsFunction";
import { ApiCalls } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsApi";
import { TIMEZONE } from "constant/calenderView";
import { useAuth0 } from "react-auth0-spa";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Spinner from "features/Appointments/Spinner/spinner";
import Dialog from "@mui/material/Dialog";
import X from "assets/X.svg";

dayjs.extend(utc);
dayjs.extend(timezone);

interface AvailabilityCardRef {
  handleValidate: () => void;
}

interface YourItemType {
  dayOfWeek: number;
  startTime: any;
  endTime: any;
  location: number;
  locationInput: number;
  isDisabled?: boolean;
}

export const EditPatientAvailabilityBuilderReview: React.FC<{}> = () => {
  const { getTokenSilently, user } = useAuth0();
  const {
    totalWeek,
    setTotalWeek,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thrusday,
    setThrusday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    clientId,
    validateUrl,
  } = useContext(myContext);
  const history = useHistory();
  const [emptyData, setEmptyData] = useState<boolean>();
  const [testingData, setTestingData] = useState<any>();
  const [testData, setTestData] = useState<any>();
  const [navigate, setNavigate] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [availabilityLoading, setAvailabilityLoading] = useState<boolean>(true);
  const [newData, setNewData] = useState<boolean>(false);

  const handleGetAvailabilityAppointments = useCallback(async () => {
    setAvailabilityLoading(false);
    const payload = {
      clientId: clientId,
    };

    try {
      const response = await patientAvailabilityApptCall(
        ApiCalls.GetPatientAvailabilityCall,
        payload,
        getTokenSilently
      );

      if (response) {
        setTestData(response?.data?.clientAvailabilities);
      }
    } catch (err) {
      setAvailabilityLoading(true);
      console.error("Error in API call:", err);
    }
  }, [clientId, getTokenSilently, setAvailabilityLoading, setTestData]);

  useEffect(() => {
    handleGetAvailabilityAppointments();
  }, [handleGetAvailabilityAppointments]);

  useEffect(() => {
    dayjs.locale("en");
    const resulteduseeffectData: YourItemType[] = testData?.map(
      ({ startTime, endTime, ...rest }: YourItemType) => {
        const currentUTCDate = dayjs();
        const startTimeData = startTime;

        const formattedStart = currentUTCDate
          .set("hour", startTimeData.split(":")[0])
          .set("minute", startTimeData.split(":")[1]);

        const FormattedStartTime = formattedStart;
        const endTimeData = endTime;

        const formattedEnd = currentUTCDate
          .set("hour", endTimeData.split(":")[0])
          .set("minute", endTimeData.split(":")[1]);

        const FormattedEndTime = formattedEnd;

        return {
          ...rest,
          startTime: dayjs(FormattedStartTime),
          endTime: dayjs(FormattedEndTime),
        };
      }
    );

    setTestingData(resulteduseeffectData);
  }, [testData]);

  const refMonday = useRef<AvailabilityCardRef | null>(null);
  const refTuesday = useRef<AvailabilityCardRef | null>(null);
  const refWednesday = useRef<AvailabilityCardRef | null>(null);
  const refThursday = useRef<AvailabilityCardRef | null>(null);
  const refFriday = useRef<AvailabilityCardRef | null>(null);
  const refSaturday = useRef<AvailabilityCardRef | null>(null);

  useEffect(() => {
    setTotalWeek({
      monday: monday,
      tuesday: tuesday,
      wednesday: wednesday,
      thrusday: thrusday,
      friday: friday,
      saturday: saturday,
    });
  }, [monday, tuesday, wednesday, thrusday, friday, saturday, setTotalWeek]);

  useEffect(() => {
    const daysOfWeek = [1, 2, 3, 4, 5, 6];
    const updatedData = daysOfWeek.map((day) => {
      return testingData
        ?.filter((item: any) => item.dayOfWeek === day)
        .map((item: any) => ({ ...item, isDisabled: true }));
    });

    const [
      mondayData,
      tuesdayData,
      wednesdayData,
      thursdayData,
      fridayData,
      saturdayData,
    ] = updatedData;

    setMonday(mondayData);
    setTuesday(tuesdayData);
    setWednesday(wednesdayData);
    setThrusday(thursdayData);
    setFriday(fridayData);
    setSaturday(saturdayData);
  }, [
    testingData,
    setMonday,
    setTuesday,
    setWednesday,
    setThrusday,
    setFriday,
    setSaturday,
  ]);

  const HandleReviewNavigation = async () => {
    setNavigate(true);
    setReload(false);
    const noDataForEveryDay = Object.values(totalWeek).every(
      (data: any) => data.length === 0
    );

    setEmptyData(noDataForEveryDay);

    refMonday.current?.handleValidate();
    refTuesday.current?.handleValidate();
    refWednesday.current?.handleValidate();
    refThursday.current?.handleValidate();
    refFriday.current?.handleValidate();
    refSaturday.current?.handleValidate();

    if (
      refMonday.current?.handleValidate() &&
      refTuesday.current?.handleValidate() &&
      refWednesday.current?.handleValidate() &&
      refThursday.current?.handleValidate() &&
      refFriday.current?.handleValidate() &&
      refSaturday.current?.handleValidate() &&
      noDataForEveryDay === false
    ) {
      const resultedData = Object.values(totalWeek).flatMap((data) =>
        (data as YourItemType[]).map(
          ({
            startTime,
            endTime,
            isDisabled,
            location,
            locationInput,
            ...rest
          }) => {
            const formattedStartTime = dayjs(startTime, { utc: true }).format(
              "HH:mm"
            );
            const formattedEndTime = dayjs(endTime, { utc: true }).format(
              "HH:mm"
            );
            return {
              ...rest,
              startTime: formattedStartTime,
              endTime: formattedEndTime,
              locationInput: location || locationInput,
            };
          }
        )
      );

      const hasIdInAllObjects = resultedData.every((item) => "id" in item);

      if (hasIdInAllObjects === false) {
        const payload = {
          clientAvailability: {
            clientId: clientId,
            availabilities: resultedData,
          },
          timezone: TIMEZONE,
          ClientName: user.name,
        };

        try {
          const response = await patientAvailabilityApptCall(
            ApiCalls.AddpatientAvailabilityCall,
            payload,
            getTokenSilently
          );

          if (response) {
            history.push({
              pathname: "/appointments-availability-close",
            });
            setNavigate(false);
          }
        } catch (err) {
          setNavigate(false);
          setReload(true);
          console.error("Error in API call:", err);
        }
      } else {
        setNewData(true);
      }
    }
    setNavigate(false);
  };

  const handleClose = () => {
    setReload(false);
  };

  const handleAvailabilityloading = () => {
    setAvailabilityLoading(false);
  };

  const handleDataClose = () => {
    setNewData(false);
  };

  const HandleHomeNavigation = () => {
    history.push({
      pathname: "/appointments",
    });
  };

  useEffect(() => {
    validateUrl && history.replace("/appointments");
  }, [validateUrl, history]);

  return (
    <div className="primary-EditPatientAvailabilityBuilderReview-container">
      <div style={{ pointerEvents: navigate ? "none" : "auto" }}>
        <div className="secondary-EditPatientAvailabilityBuilderReview-container">
          <div className="primary-text">Update your Availability</div>
          <div className="secondary-text">
            <span>
              Please update your general availability for as many hours as
              possible throughout the week. Remember, the more hours you're
              available, the easier it is for our team to find you options.
              Additionally, morning hours generally entail shorter waiting times
              compared to afternoons.
              <br />
              <br />
              Please review your current availability and add times and days for{" "}
              <span className="secondary-text-bold"> in clinic therapies.</span>
            </span>
          </div>
          {testData ? (
            <>
              <EditAvailabilityCard
                key="Monday"
                heading="Monday"
                locations={setMonday}
                locationData={monday}
                forwardedRef={refMonday}
                dayOfWeek={dayOfWeek.monday}
              />
              <EditAvailabilityCard
                key="Tuesday"
                heading="Tuesday"
                locations={setTuesday}
                locationData={tuesday}
                forwardedRef={refTuesday}
                dayOfWeek={dayOfWeek.tuesday}
              />
              <EditAvailabilityCard
                key="Wednesday"
                heading="Wednesday"
                locations={setWednesday}
                locationData={wednesday}
                forwardedRef={refWednesday}
                dayOfWeek={dayOfWeek.wednesday}
              />
              <EditAvailabilityCard
                key="Thursday"
                heading="Thursday"
                locations={setThrusday}
                locationData={thrusday}
                forwardedRef={refThursday}
                dayOfWeek={dayOfWeek.thursday}
              />
              <EditAvailabilityCard
                key="Friday"
                heading="Friday"
                locations={setFriday}
                locationData={friday}
                forwardedRef={refFriday}
                dayOfWeek={dayOfWeek.friday}
              />
              <EditAvailabilityCard
                key="Saturday"
                heading="Saturday"
                locations={setSaturday}
                locationData={saturday}
                forwardedRef={refSaturday}
                dayOfWeek={dayOfWeek.saturday}
              />
              <Dialog
                open={reload}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="dialogue-reload"
              >
                <div className="dialogue-reload-edit-container">
                  <div className="dialogue-X-img">
                    <img src={X} alt="Loading" onClick={handleClose} />
                  </div>
                  <div className="dialogue-Heading">
                    Something went wrong. Please try again in a moment.
                  </div>
                  <div className="button">
                    <Button
                      className="Warning-button"
                      onClick={HandleReviewNavigation}
                    >
                      Try again
                    </Button>
                  </div>
                </div>
              </Dialog>
              <Dialog
                open={newData}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="dialogue-reload"
              >
                <div className="dialogue-reload-edit-container">
                  <div className="dialogue-X-img">
                    <img src={X} alt="Loading" onClick={handleDataClose} />
                  </div>
                  <div className="dialogue-Heading">
                    Add at least one new Availability to proceed further
                  </div>
                  <div className="button">
                    <Button
                      className="Warning-button"
                      onClick={handleDataClose}
                    >
                      Ok
                    </Button>

                    <Button
                      className="Warning-button"
                      onClick={HandleHomeNavigation}
                    >
                      Exit
                    </Button>
                  </div>
                </div>
              </Dialog>
            </>
          ) : (
            <>
              <Spinner />
              {availabilityLoading && (
                <Dialog
                  open={availabilityLoading}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className="dialogue-reload"
                >
                  <div className="dialogue-reload-edit-container">
                    <div className="dialogue-X-img">
                      <img
                        src={X}
                        alt="Loading"
                        onClick={handleAvailabilityloading}
                      />
                    </div>
                    <div className="dialogue-Heading">
                      Something went wrong. Please try again in a moment.
                    </div>
                    <div className="button">
                      <Button
                        className="Warning-button"
                        onClick={handleGetAvailabilityAppointments}
                      >
                        Try again
                      </Button>
                    </div>
                  </div>
                </Dialog>
              )}
            </>
          )}
        </div>
        {emptyData === true && (
          <div className="validate-text">
            Please select at least one card to proceed
          </div>
        )}
      </div>
      {testData ? (
        <div className="Button-container">
          <Button
            className="Next-button"
            onClick={HandleReviewNavigation}
            disabled={navigate}
            loading={navigate}
            style={{ pointerEvents: navigate ? "none" : "auto" }}
          >
            {navigate ? "loading..." : "Submit"}
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
