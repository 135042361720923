import React, { useContext, useEffect } from "react";
import Availabiity from "../../Availability/Availability";
import Day from "assets/Step 2.svg";
import "./patientAvailabilityDay.scss";
import Location from "assets/Location.svg";
import { useHistory } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Button, Checkbox, Grid } from "@mui/material";
import { myContext } from "features/Hooks/useContext";

export const PatientAvailabiityDay: React.FC<{}> = () => {
  const history = useHistory();
  const {
    daysChecked,
    setDaysChecked,
    carePlan,
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thrusday,
    setThrusday,
    friday,
    setFriday,
    saturday,
    setSaturday,
    validateUrl,
  } = useContext(myContext);
  const HandleLocationNavigation = () => {
    history.push({
      pathname: "/appointments-availability-builder",
    });
  };

  const HandleHomeNavigation = () => {
    history.push({
      pathname: "/appointments-availability",
    });
  };

  const handleDayChange = (day: any) => (event: any) => {
    const isChecked = event.target.checked;

    setDaysChecked({
      ...daysChecked,
      [day]: isChecked,
    });

    if (!isChecked) {
      switch (day) {
        case "Monday":
          setMonday([]);
          break;
        case "Tuesday":
          setTuesday([]);
          break;
        case "Wednesday":
          setWednesday([]);
          break;
        case "Thursday":
          setThrusday([]);
          break;
        case "Friday":
          setFriday([]);
          break;
        case "Saturday":
          setSaturday([]);
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    setDaysChecked({
      Monday: monday?.length > 0 ? true : false,
      Tuesday: tuesday?.length > 0 ? true : false,
      Wednesday: wednesday?.length > 0 ? true : false,
      Thursday: thrusday?.length > 0 ? true : false,
      Friday: friday?.length > 0 ? true : false,
      Saturday: saturday?.length > 0 ? true : false,
    });
  }, [monday, tuesday, wednesday, thrusday, friday, saturday, setDaysChecked]);

  const dayLabels = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const dayCheckboxes = dayLabels.map((day) => (
    <FormControlLabel
      key={day}
      label={day}
      control={
        <Checkbox checked={daysChecked[day]} onChange={handleDayChange(day)} />
      }
    />
  ));
  useEffect(() => {
    validateUrl && history.replace("/appointments");
  }, [validateUrl, history]);

  const isAnyDaySelected = Object.values(daysChecked).some((day) => day);

  return (
    <div className="primary-PatientAvailabilityDay-container">
      <div>
        <div className="Location-navigation">
          <div className="Navigation">
            <img
              className="Home-img"
              src={Location}
              alt="Loading"
              onClick={HandleHomeNavigation}
            />
          </div>
        </div>
        <Availabiity />
        <div className="secondary-PatientAvailabilityDay-container">
          <img className="Day-img" src={Day} alt="Loading" />
          <div className="primary-text">
            Select the days you’re available for {carePlan[0]?.therapy} Therapy
            appointments.
          </div>
          <div className="primary-text">
            Hint: The more days you select, the more likely it is we can find a
            provider to get the appointments started.
          </div>
          <div className="third-PatientAvailabilityDay-container">
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <div>
                  <div className="checkBox">{dayCheckboxes}</div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div className="Button-container">
        <Button
          className="Next-button"
          variant="contained"
          onClick={HandleLocationNavigation}
          disabled={isAnyDaySelected ? false : true}
        >
          Next
        </Button>
      </div>
    </div>
  );
};
