const apiRequest =
  process.env.REACT_APP_STAGE === "local"
    ? `http://localhost:8000`
    : `${process.env.REACT_APP_BACKEND_API}`;

const ApiCalls = {
  ConfirmEditCall: "editProviderAppointment",
  ConfirmCancelCall: "cancelProviderAppointment",
  ConfirmAddCall: "createproviderappt",
  ConfirmAllCall: "appointments",
  ConfirmConversionCall: "checkifeventconverted",
  ConfirmDayCall: "confirmBiAppointments",
  ConfirmDayStatusCall: "checkifdayconfirmed",
  AppointmentResyncCall: "appointmentResync",
  driveTimeEdit: "driveTimeEdit",
  AddpatientAvailabilityCall: "addPatientAvailability",
  GetPatientAvailabilityCall: "getPatientAvailability",
  patientAvailability: "patientAvailability",
  patientClinics: "patientClinics",
  rcSuggestions: "patientPortalRscSuggestions",
  rsSubmittion: "patientPortalRscBooking",
};

export { apiRequest, ApiCalls };
