import moment from "moment";
const momentTz = require("moment-timezone");

export function DiffMinute(startTime: string, endTime: string) {
  return moment.duration(moment(endTime).diff(moment(startTime))).asMinutes();
}

export function AddDays(startDate: string, days: number) {
  return moment(startDate).add(days, "days");
}

export function formatTime(timeString: string, zone: any) {
  return momentTz.tz(timeString, zone).format("hh:mm a");
}

export function startTimeAndendtime(startDate: string, endDate: string) {
  return `${moment(startDate).format("h:mm a")} - ${moment(endDate).format(
    "h:mm a"
  )}`;
}

export function rescheduledDate(startTime: string) {
  return moment(startTime).format("dddd MMMM D, YYYY");
}
