import { appointments } from "model/calender/appointments";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./clientReschedule.scss";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import moment from "moment";
import { Dayjs } from "dayjs";
import { myContext } from "features/Hooks/useContext";
import { default_Dropdown } from "./staticRescheduleData";
import ClientRescheduleCalender from "./ClientRescheduleCalender";
import { ClientRescheduleBusy } from "./ClientRescheduleBusy";
import { ApiCalls } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsApi";
import { useAuth0 } from "react-auth0-spa";
import { clientRescheduleCall } from "features/Appointments/biportal/AppointmentForms/AllAppointmentsFunction";
import { Dialog } from "@mui/material";
import { Button } from "antd";
import X from "assets/X.svg";

dayjs.locale("en");

interface LocationState {
  activeDay: string;
  appointment: appointments;
  path: string;
  payload: any;
}

interface ContextType {
  state: any;
  setState: React.Dispatch<React.SetStateAction<any>>;
  setPrefDate: React.Dispatch<React.SetStateAction<string>>;
  validateUrl: boolean;
}

export const ClientReschedule: React.FC<{}> = () => {
  const { getTokenSilently } = useAuth0();
  const { state, setState, validateUrl } = useContext<ContextType>(myContext);
  const location = useLocation<LocationState>();
  const { appointment, activeDay, path, payload } = location.state;
  const history = useHistory();

  console.log(path, activeDay, payload, "[reschedule] path data");

  console.log(
    appointment,
    new Date(moment(activeDay).toISOString()),
    "[reschedule] appointments and activeDay"
  );

  console.log(state, "[reschedule] global state");

  const {
    selectedDate,
    initialDateSet,
    clickedIndex,
    dropdown,
    textArea,
    rcSuggestions,
    error,
    newStartTime,
    newEndTime,
  } = state;

  console.log(
    selectedDate,
    initialDateSet,
    clickedIndex,
    dropdown,
    textArea,
    rcSuggestions,
    error,
    newStartTime,
    newEndTime,
    "[reschedule] all state Data"
  );

  console.log(dropdown, "[reschedule] dropdown top");

  const ButtonDisable = clickedIndex === -1 || dropdown === default_Dropdown;

  console.log(
    clickedIndex,
    ButtonDisable,
    dropdown,
    "[reschedule] clicked index"
  );

  console.log(rcSuggestions, "[reschedule] rcSuggestions");

  console.log(appointment, "[clientReschedule] appointments");

  console.log(selectedDate, "[reschedule] [selectedDate]");

  const isDateEnabled = useCallback(
    (date: Dayjs): boolean => {
      const dateString = date.format("MM/DD/YYYY");
      return rcSuggestions?.some((item: any) => item.date === dateString);
    },
    [rcSuggestions]
  );

  console.log(selectedDate, "[reschedule] selectedDate");
  // useEffect(() => {
  //   if (!initialDateSet) {
  //     const initialDate = rcSuggestions?.find((item: any) =>
  //       isDateEnabled(dayjs(item.date, "MM/DD/YYYY"))
  //     );
  //     if (initialDate) {
  //       setState((prevState: any) => ({
  //         ...prevState,
  //         selectedDate: initialDate,
  //         initialDateSet: true,
  //       }));
  //     }
  //   }
  // }, [initialDateSet, rcSuggestions, isDateEnabled, setState]);

  useEffect(() => {
    if (!initialDateSet && rcSuggestions.length > 0) {
      const sortedSuggestions = rcSuggestions.slice().sort((a: any, b: any) => {
        return (
          dayjs(a.date, "MM/DD/YYYY").valueOf() -
          dayjs(b.date, "MM/DD/YYYY").valueOf()
        );
      });
      const initialDate = sortedSuggestions.find((item: any) =>
        isDateEnabled(dayjs(item.date, "MM/DD/YYYY"))
      );
      if (initialDate) {
        setState((prevState: any) => ({
          ...prevState,
          selectedDate: initialDate,
          initialDateSet: true,
        }));
      }
    }
  }, [initialDateSet, rcSuggestions, isDateEnabled, setState]);

  const formatAppointmentDateTime = (start: string, end: string) => {
    const formattedStartDate = moment(start).format("dddd MMMM D, YYYY");
    const formattedStartTime = moment(start).format("h:mm a");
    const formattedEndTime = moment(end).format("h:mm a");

    return `${formattedStartDate} at ${formattedStartTime} - ${formattedEndTime}`;
  };

  const earliestEnabledDate = useMemo(() => {
    const enabledDates = rcSuggestions
      ?.map((item: any) => dayjs(item.date, "MM/DD/YYYY"))
      .filter(isDateEnabled);
    console.log(enabledDates, "[reschedule] enable Dates");

    if (enabledDates && enabledDates.length > 0) {
      enabledDates.sort((a: any, b: any) => a.valueOf() - b.valueOf());
      return selectedDate?.date === undefined
        ? enabledDates[0]
        : dayjs(selectedDate?.date) === enabledDates[0]
        ? enabledDates[0]
        : dayjs(selectedDate?.date);
    }
  }, [rcSuggestions, isDateEnabled, selectedDate]);

  console.log(earliestEnabledDate, "[reschedule] earliestEnabledDate");

  console.log(
    dayjs(selectedDate, "MM/DD/YYYY"),
    earliestEnabledDate,
    "[reschedule] selectedDate and earlistEnabledDate"
  );

  useEffect(() => {
    validateUrl && history.replace("/appointments");
  }, [validateUrl, history]);

  const handleRcSuggestions = useCallback(
    async (payload: any) => {
      console.log(payload, "[clientreschedule] payload inside the function");
      setState((prevData: any) => ({ ...prevData, error: false }));
      try {
        const response = await clientRescheduleCall(
          ApiCalls.rcSuggestions,
          payload,
          getTokenSilently
        );

        if (response && response.data) {
          setState((prevState: any) => ({
            ...prevState,
            rcSuggestions: response.data?.sameProviderMakeupSessions,
          }));
        } else {
          console.error("Error: No data received from API response");
        }
      } catch (err) {
        setState((prevData: any) => ({ ...prevData, error: true }));
        console.error("Error in API call:", err);
      }
    },
    [
      location.pathname,
      getTokenSilently,
      payload,
      setState,
      activeDay,
      appointment,
      history,
      path,
    ]
  );

  useEffect(() => {
    if (!error) {
      const interval = setInterval(() => {
        if (location.pathname === "/client-reschedule-appointment") {
          handleRcSuggestions(payload);
        }
      }, 2 * 60 * 1000);

      return () => clearInterval(interval);
    }
    return;
  }, [handleRcSuggestions, location.pathname]);

  const handleClose = () => {
    setState((prevData: any) => ({ ...prevData, error: false }));
    history.push({
      pathname: "/appointments",
    });
  };

  return (
    <div className="client-reschedule-main-container">
      {!error ? (
        rcSuggestions?.length > 0 ? (
          <div className="client-reschedule-container">
            <span className="rescheduling-heading">
              {`Let’s find a better time! Let’s reschedule your appointment!`}
            </span>
            <span className="rescheduling-paragraph">
              {appointment?.client_name}’s {appointment?.type_name} appointment
              is scheduled for:{" "}
              <span className="rescheduling-paragraph-bold">
                {formatAppointmentDateTime(
                  appointment?.start,
                  appointment?.end
                )}
                .
              </span>
              <div className="empty-space"></div>
              <span className="rescheduling-paragraph">
                Select a new appointment date within the next 14 business days
                from the original appointment, and then proceed to choose your
                preferred appointment time.
              </span>
            </span>

            <ClientRescheduleCalender
              rcSuggestions={rcSuggestions}
              selectedDate={selectedDate}
              earliestEnabledDate={earliestEnabledDate}
              clickedIndex={clickedIndex}
              dropdown={dropdown}
              activeDay={activeDay}
              appointment={appointment}
              path={path}
              textAreaValue={textArea}
              payload={payload}
            />
          </div>
        ) : (
          <ClientRescheduleBusy activeDay={activeDay} path={path} />
        )
      ) : (
        <Dialog
          open={error}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="dialogue-reload"
        >
          <div className="client-reschedule-dialogue-flow-reload-pop-up">
            <div className="client-dialogue-X-img">
              <img src={X} alt="Loading" onClick={handleClose} />
            </div>
            <div className="client-dialogue-Heading">
              Something went wrong. Please try again in a moment.
            </div>
            <div className="client-button">
              <Button
                className="Warning-button"
                onClick={() => handleRcSuggestions(payload)}
              >
                Try again
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};
