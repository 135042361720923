import React, { useState, useEffect, useRef, useMemo } from "react";
import { useAuth0 } from "react-auth0-spa";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "assets/blue-logo.png";
import { AvailabilityExit } from "features/Appointments/AvailabilityExit/AvailabilityExit";
import "./header.sass";

const Header = () => {
  const history = useHistory();
  const { loading, user, logout, isAuthenticated } = useAuth0();
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (menuOpen && menuRef.current && !menuRef.current.contains(e.target)) {
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [menuOpen]);

  const pathNamesToOpen = useMemo(
    () => [
      "/appointments-availability",
      "/appointments-availability-start",
      "/appointments-availability-days",
      "/appointments-availability-builder",
      "/appointments-availability-review",
      "/appointments-availability-builder-review",
      "/edit-appointments-availiability",
      "/redirect-availability",
      "/avail",
    ],
    []
  );

  const isPathToOpen = pathNamesToOpen.includes(location.pathname);

  const redirectToHome = () => {
    const newPath = user["https://corticacare.com/app_metadata"]?.provider
      ? "/providerappointments"
      : "/appointments";
    history.push({ pathname: newPath });
  };

  const handleLogoClick = () => {
    if (location.pathname !== "/appointments-availability-close") {
      isPathToOpen ? setOpen(true) : redirectToHome();
    }
  };

  return (
    <div className="header">
      <div className="logo">
        <img src={Logo} alt="cortica-logo" onClick={handleLogoClick} />
      </div>
      {!loading && isAuthenticated && (
        <div ref={menuRef} className="info" onClick={toggle}>
          {menuOpen && (
            <div className="sign-out">
              <div className="user-info">
                <span>{user.email}</span>
              </div>
              <span onClick={logout}>Log Out</span>
            </div>
          )}
          <div className="toggle-wrapper">
            <div className="username">
              <p>{user.nickname.charAt(0).toUpperCase()}</p>
            </div>
          </div>
        </div>
      )}
      {isPathToOpen && <AvailabilityExit open={open} setOpen={setOpen} />}
    </div>
  );
};

export default Header;
