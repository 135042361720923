import { Button } from "@mui/material";
import React, { useContext, useEffect } from "react";
import AvailabilityThankYou from "assets/Availability Thank you.svg";
import "./patientAvailabilityClose.scss";
import { useHistory } from "react-router-dom";
import { myContext } from "features/Hooks/useContext";

export const PatientAvailabilityClose: React.FC<{}> = () => {
  const history = useHistory();
  const { setValidateUrl } = useContext(myContext);

  const HandleNavigationHome = () => {
    history.push({
      pathname: "/appointments",
    });
  };

  useEffect(() => {
    setValidateUrl(true);
  }, []);

  return (
    <div className="primary-PatientAvailabilityClose-container">
      <div className="secondary-PatientAvailabilityClose-container">
        <div className="primary-text">Thank you!</div>
        <div className="Thank-you-img">
          <img className="Thank-you" src={AvailabilityThankYou} alt="Loading" />
        </div>
        <div className="Text">
          <div className="Inner-text">
            We’ve shared your availability with our Scheduling Team. They’ll
            begin the process of creating a weekly schedule for this
            recommendation.
          </div>
          <div className="Inner-text">
            They will send you an email as soon as possible to discuss options
            and get you started with appointments.
          </div>
          <div className="Inner-text">
            If you need to modify your availability, please call or email the
            Scheduling Team at 888-885-5068 or scheduling@corticacare.com
          </div>
        </div>
      </div>

      <div className="Button-container">
        <Button
          className="Next-button"
          variant="contained"
          onClick={HandleNavigationHome}
        >
          Home
        </Button>
      </div>
    </div>
  );
};
