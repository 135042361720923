import {
  AddressInterface,
  Appointment,
  CancelDropdownOptions,
  eventAddressData,
} from "model/appointments";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm, Controller, FormProvider, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CLIENT_EDIT_APPOINTMENT_SCHEMA,
  ADMIN_APPOINTMENT_EDIT_SCHEMA,
} from "../../../../utils/BIPortal/Validators/appointmets";
import { EditAppointmentWrapper } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { FormItem } from "../../../../sharedComponents/reactHookComponents/formItem";
import {
  getEditAppointmentInitValues,
  compareTimes,
  adjustDateHours,
} from "./appointments.helper";
import { Select, TimerTextField } from "sharedComponents/reactHookComponents";
import { RESCHEDULE_REASONS } from "constant/BiPortalConstants";
import moment from "moment";
import ConfirmEdit from "./ConfirmEdit";
import dayjs from "dayjs";
import DriveTimeMileage from "./DriveTimeMileage";
import { useWebSocket } from "utils/WebSockets/WebSocketProvider";
import CrBannerError from "sharedComponents/Error/crBannerError";
import SpinnerV2 from "../../spinnerV2/spinnerV2";
import { useAuth0 } from "react-auth0-spa";
import axios from "axios";
import PtoConflictErrors from "sharedComponents/Error/ptoConflictErrors";
import { TextAreaField } from "sharedComponents/reactHookComponents/textfield";

interface LocationState {
  providerId: number;
  appointment: Appointment;
  activeDay?: string;
}

const EditAppointment: React.FC = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const { getTokenSilently } = useAuth0();
  const { webSocketData } = useWebSocket();

  const appointmentInfo = location.state.appointment;

  const appointmentStartDate = new Date(
    appointmentInfo.startAt
  ).toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
  const appointmentStartTime = new Date(
    appointmentInfo.startAt
  ).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const appointmentEndTime = new Date(appointmentInfo.endAt).toLocaleTimeString(
    "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  );
  const activeDay = location.state.activeDay;
  const isAdmin = appointmentInfo.appointment_type === "Admin";
  const selectedDay = appointmentInfo.start;
  const isDriveTime =
    appointmentInfo.title === "Drive Time" ||
    appointmentInfo.title === "Mileage Only";
  const providerId = appointmentInfo.provider_id;
  const editReason: CancelDropdownOptions[] = RESCHEDULE_REASONS;
  const [displayBanner, setDisplayBanner] = useState<boolean>(false);
  const [isFormVisible, setVisible] = useState(true);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [headerTitle, setHeaderTitle] = useState<string>("Update Appointment");
  const [timeValidate, setTimeValidate] = React.useState<boolean>(false);
  const [ptoConflictCheck, initiatePtoConflictCheck] = useState<boolean>(false);
  const [hasConflictingPTO, setHasConflictingPTO] = useState<boolean>(false);
  const [ptoStartDate, setPtoStartDate] = useState<boolean>();
  const [ptoEndDate, setPtoEndDate] = useState<boolean>();
  const [isBeforeStartTime, setIsBeforeStartTime] =
    React.useState<boolean>(false);
  const [isTimePrestine, setTimePrestine] = React.useState<boolean>(false);
  const [showReasons, setShowReasons] = React.useState<boolean>(false);

  const [commonFieldsChanged, setCommonFieldsChanged] =
    React.useState<boolean>(false);

  const [mileAgeCalculation, setMileAgeCalculation] =
    React.useState<boolean>(false);

  const [fetchedEventAddress, setFetchedStartingAddress] = useState<
    eventAddressData | undefined
  >();
  const [fullStartingAddress, setFullStartingAddress] = useState<
    AddressInterface | undefined
  >({
    addressLine1: "",
    city: "",
    state: "",
    freeformAddress: "",
    postalCode: "",
    lat: "",
    lon: "",
  });
  const [fullEndingAddress, setFullEndingAddress] = useState<
    AddressInterface | undefined
  >({
    addressLine1: "",
    city: "",
    state: "",
    freeformAddress: "",
    postalCode: "",
    lat: "",
    lon: "",
  });
  const [showAddressFields, setShowAddressFields] =
    React.useState<boolean>(false);

  const [addressLoader, initiateAddressLoader] = useState<boolean>(false);

  const [mileError, setMileError] = useState<boolean>(false);

  const [startingAddressSuggestions, setStartingAddressSuggestions] = useState<
    any[]
  >([]);
  const [destinationAddressSuggestions, setDestinationAddressSuggestions] =
    useState<any[]>([]);
  const [fetchingStartAddress, setFetchingStartAddress] =
    useState<boolean>(false);
  const [fetchingEndAddress, setFetchingEndAddress] = useState<boolean>(false);

  const [startPrestineStatus, setStartPrestineStatus] = useState<boolean>(true);
  const [endPrestineStatus, setEndPrestineStatus] = useState<boolean>(true);
  const [isFirstLastDrive, setIsFirstLastDrive] = useState<boolean | null>(
    appointmentInfo?.isFirstOrLastDriveToNonClinic!
  );
  const [isExceedingMaxMiles, setIsExceedingMaxMiles] =
    useState<boolean>(false);
  const methods = useForm<any>({
    defaultValues: getEditAppointmentInitValues(appointmentInfo),
    resolver: isAdmin
      ? yupResolver(ADMIN_APPOINTMENT_EDIT_SCHEMA)
      : yupResolver(CLIENT_EDIT_APPOINTMENT_SCHEMA),
    mode: "all",
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: "onChange",
  });

  const startDate = useWatch({
    control: methods.control,
    name: ["startTime"],
  });

  const endDate = useWatch({
    control: methods.control,
    name: ["endTime"],
  });

  const checkTimePrestineStatus = () => {
    const startTimeStatus = compareTimes(
      methods.getValues(["startTime"])[0],
      appointmentInfo.start,
      true
    );
    const endTimeStatus = compareTimes(
      methods.getValues(["endTime"])[0],
      appointmentInfo.end,
      true
    );

    if (startTimeStatus && endTimeStatus) {
      return true;
    } else {
      return false;
    }
  };

  const checkStartPrestineStatus = () => {
    const startTimeStatus = compareTimes(
      methods.getValues(["startTime"])[0],
      appointmentInfo.start,
      true
    );

    return startTimeStatus;
  };

  const checkEndPrestineStatus = () => {
    const endTimeStatus = compareTimes(
      methods.getValues(["endTime"])[0],
      appointmentInfo.end,
      true
    );

    return endTimeStatus;
  };

  useEffect(() => {
    const status = checkStartPrestineStatus();
    setStartPrestineStatus(status);
    if (status) {
      methods.setValue("startReason", null);
    }
  }, [startDate]);

  useEffect(() => {
    const status = checkEndPrestineStatus();
    setEndPrestineStatus(status);
    if (status) {
      methods.setValue("endReason", null);
    }
  }, [endDate]);

  useEffect(() => {
    if (webSocketData?.athena || webSocketData?.cr) {
      if (webSocketData?.athena !== true || webSocketData?.cr !== true) {
        setDisplayBanner(true);
      } else {
        setDisplayBanner(false);
      }
    } else {
      setDisplayBanner(false);
    }
  }, [webSocketData]);

  useEffect(() => {
    if (dayjs.isDayjs(methods.getValues("endTime"))) {
      methods.setValue(
        "endTime",
        moment(methods.getValues("endTime").toDate())
      );
    }
    if (dayjs.isDayjs(methods.getValues("startTime"))) {
      methods.setValue(
        "startTime",
        moment(methods.getValues("startTime").toDate())
      );
    }
  }, [methods.getValues(["endTime", "startTime"])]);

  const startDateHandler = React.useCallback(
    (value: any) => {
      const date = moment(value.$d);
      const time = date;

      methods.setValue("startTime", time);
      methods.trigger(["startTime"]);
      methods.setValue("startDirty", true);
      const endDate = methods.getValues("endTime");
      if (endDate) {
        const momentStartValue = moment(time, "HH:mm");
        const momentEndValue = moment(endDate, "HH:mm");
        if (
          momentEndValue.isBefore(momentStartValue) ||
          momentEndValue.isSame(momentStartValue)
        ) {
          setIsBeforeStartTime(true);
          setTimeValidate(true);
        } else {
          setTimeValidate(false);
          setIsBeforeStartTime(false);
        }
        methods.trigger("endDate");
      }
      setTimePrestine(checkTimePrestineStatus());
    },
    [methods]
  );

  const endDateHandler = React.useCallback(
    (value: any) => {
      const date = moment(value.$d);
      const time = date;
      methods.setValue("endTime", time);
      methods.setValue("endDirty", true);
      const startDate = methods.getValues("startTime");
      if (startDate) {
        const momentStartValue = moment(startDate, "HH:mm");
        const momentEndValue = moment(time, "HH:mm");
        if (
          momentEndValue.isBefore(momentStartValue) ||
          momentEndValue.isSame(momentStartValue)
        ) {
          setIsBeforeStartTime(true);
          setTimeValidate(true);
        } else {
          setTimeValidate(false);
          setIsBeforeStartTime(false);
        }
        methods.trigger("startDate");
      }
      methods.trigger("endDate");
    },
    [methods]
  );

  useEffect(() => {
    const disableReason = checkTimePrestineStatus();
    disableReason ? setShowReasons(false) : setShowReasons(true);
    if (!isDriveTime) {
      const commonFieldsChange = checkTimePrestineStatus();
      setCommonFieldsChanged(!commonFieldsChange);
    } else {
      setCommonFieldsChanged(true);
    }
  }, [startDate, endDate]);

  const startingAddressHandler = React.useCallback(
    (value: any) => {
      methods.setValue("startingAddress", value.freeformAddress);

      methods.trigger("startingAddress");
      setFullStartingAddress(value);
    },
    [methods]
  );

  const destinationAddressHandler = React.useCallback(
    (value: any) => {
      methods.setValue("destinationAddress", value.freeformAddress);

      methods.trigger("destinationAddress");
      setFullEndingAddress(value);
    },
    [methods]
  );

  const milesHandler = React.useCallback(
    (value: any) => {
      methods.setValue("miles", Number(value));

      methods.trigger("miles");
    },
    [methods]
  );

  const driveTimeHandle = React.useCallback(
    (val: any) => {
      methods.setValue("isFirstLastDrive", val);
      setIsFirstLastDrive(val);
    },
    [methods]
  );

  const reimburseableMilesHandler = React.useCallback(
    (value: any) => {
      methods.setValue("reimburseableMiles", Number(value));

      methods.trigger("reimburseableMiles");
    },
    [methods]
  );

  const resetDriveTimeMap = () => {
    setShowAddressFields(false);
    setFetchedStartingAddress(undefined);
    setFullStartingAddress({
      addressLine1: "",
      city: "",
      state: "",
      freeformAddress: "",
      postalCode: "",
      lat: "",
      lon: "",
    });
    setFullEndingAddress({
      addressLine1: "",
      city: "",
      state: "",
      freeformAddress: "",
      postalCode: "",
      lat: "",
      lon: "",
    });
    setFetchingStartAddress(false);
    setFetchingEndAddress(false);
    setStartingAddressSuggestions([]);
    setDestinationAddressSuggestions([]);
    resetMethodAddresses();
  };

  const resetMethodAddresses = () => {
    methods.setValue("startingAddress", null);
    methods.setValue("destinationAddress", null);
    methods.setValue("miles", null);
    methods.setValue("reimburseableMiles", null);
  };

  useEffect(() => {
    resetDriveTimeMap();
  }, [startDate, endDate]);

  const checkPtoConflicts = async () => {
    setHasConflictingPTO(false);
    initiatePtoConflictCheck(true);
    const accessToken = await getTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const apiUrl =
        process.env.REACT_APP_STAGE === "local"
          ? "http://localhost:8000/checkptoconflicts"
          : `${process.env.REACT_APP_BACKEND_API}/checkptoconflicts`;

      const response = await axios.post(
        apiUrl,
        {
          providerId: providerId,
          startTime: adjustDateHours(selectedDay, startDate[0]),
          endTime: adjustDateHours(selectedDay, endDate[0]),
        },
        config
      );

      setHasConflictingPTO(response.data.hasConflicts);
      setPtoStartDate(response.data.ptoStartDate);
      setPtoEndDate(response.data.ptoEndDate);

      initiatePtoConflictCheck(false);
    } catch (error) {
      initiatePtoConflictCheck(false);
    }
  };
  const fetchEventAddresses = async () => {
    initiateAddressLoader(true);
    const accessToken = await getTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const apiUrl =
        process.env.REACT_APP_STAGE === "local"
          ? "http://localhost:8000/fetchaxoneventaddresses"
          : `${process.env.REACT_APP_BACKEND_API}/fetchaxoneventaddresses`;

      const payloadStart = adjustDateHours(selectedDay, startDate[0]);
      const payloadEnd = adjustDateHours(selectedDay, endDate[0]);

      const response = await axios.post(
        apiUrl,
        {
          providerId: providerId,
          startTime: payloadStart, // assuming startDate is an array
          endTime: payloadEnd, // assuming endDate is an array
          selectedDay: selectedDay, // assuming selectedDay is an array
        },
        config
      );

      const { startingAddress, destinationAddress } = response.data;

      setFetchedStartingAddress({
        startingAddress: startingAddress,
        endingAddress: destinationAddress,
      });

      initiateAddressLoader(false);

      setShowAddressFields(true);
    } catch (error) {
      initiateAddressLoader(false);
      setMileError(true);
      // Handle error if needed
    }
  };

  const fetchAddressSuggestions = async (query: string): Promise<any[]> => {
    const accessToken = await getTokenSilently();
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    try {
      const apiUrl =
        process.env.REACT_APP_STAGE === "local"
          ? "http://localhost:8000/fetchaddresssuggestions"
          : `${process.env.REACT_APP_BACKEND_API}/fetchaddresssuggestions`;

      const response = await axios.post(
        apiUrl,
        {
          query: query,
        },
        config
      );

      const suggestions = response.data;
      return suggestions;
    } catch (error: any) {
      return [];
    }
  };

  useEffect(() => {
    if (fetchedEventAddress?.startingAddress) {
      setFetchingStartAddress(true);
      fetchAddressSuggestions(fetchedEventAddress?.startingAddress).then(
        (suggestions) => {
          setStartingAddressSuggestions(suggestions);
          if (suggestions?.length) {
            setFullStartingAddress(suggestions[0]);
            methods.setValue("startingAddress", suggestions[0].freeformAddress);
            setFetchingStartAddress(false);
          }
        }
      );
    } else {
      setStartingAddressSuggestions([]);
      setFullStartingAddress(undefined);
      methods.setValue("startingAddress", null);
    }

    if (fetchedEventAddress?.endingAddress) {
      setFetchingEndAddress(true);
      fetchAddressSuggestions(fetchedEventAddress?.endingAddress).then(
        (suggestions) => {
          setDestinationAddressSuggestions(suggestions);
          if (suggestions?.length) {
            setFullEndingAddress(suggestions[0]);
            methods.setValue(
              "destinationAddress",
              suggestions[0].freeformAddress
            );
            setFetchingEndAddress(false);
          }
        }
      );
    } else {
      setDestinationAddressSuggestions([]);
      setFullEndingAddress(undefined);
      methods.setValue("destinationAddress", null);
    }
  }, [
    fetchedEventAddress?.startingAddress,
    fetchedEventAddress?.endingAddress,
  ]);

  const handleBackClick = () => {
    if (isFormVisible) {
      history.push({
        pathname: "/providerappointments",
        state: {
          prefStartDate: activeDay,
        }, // New route
      });
    } else {
      setHeaderTitle("Edit Appointment");
      setVisible(true);
    }
  };

  const startReasonHandler = React.useCallback(
    (val: number) => {
      methods.setValue("startReason", val);
    },
    [methods]
  );

  const endReasonHandler = React.useCallback(
    (val: number) => {
      methods.setValue("endReason", val);
    },
    [methods]
  );
  const reasonHandler = React.useCallback(
    (val: number) => {
      methods.setValue("reason", val);
    },
    [methods]
  );

  const submitForm = React.useCallback(() => {
    const prestineCheck = checkTimePrestineStatus();
    if (prestineCheck && !isDriveTime) {
      setTimePrestine(true);
    } else {
      setTimePrestine(false);
      setVisible(false);
      setHeaderTitle("Confirm New Time");
    }
  }, []);
  const submitError = React.useCallback(() => {
    methods.trigger();
  }, [methods]);

  useEffect(() => {
    setTimeout(() => {
      setHasConflictingPTO(false);
    }, 0);
    const fetchData = async () => {
      if (startDate[0] !== undefined && endDate[0] !== undefined) {
        if (!isBeforeStartTime) {
          await checkPtoConflicts();
        }
      }
      await new Promise((resolve) => setTimeout(resolve, 500));
      if (
        //TODO: @sushma @sravan don't hardcode

        isDriveTime &&
        !isBeforeStartTime &&
        startDate[0] !== undefined &&
        endDate[0] !== undefined
      ) {
        await fetchEventAddresses();
      } else {
        setShowAddressFields(false);
      }
    };

    const timer = setTimeout(fetchData, 500);

    return () => clearTimeout(timer);
  }, [startDate, endDate]);

  useEffect(() => {
    if (isFormVisible) {
      setShowConfirm(false);
    } else {
      setShowConfirm(true);
    }
  }, [isFormVisible]);

  return (
    <EditAppointmentWrapper>
      <div className="w-100 d-flex h-center">
        <div className="header-div">
          <div className="back-to h-end v-center">
            <button onClick={handleBackClick}>
              <FontAwesomeIcon className="icon-back" icon={faChevronLeft} />
            </button>
          </div>
          <div className="page-desc h-center v-center">
            <span>{headerTitle}</span>
          </div>
          <div className="back-to"></div>
        </div>
      </div>
      {displayBanner && (
        <div>
          <CrBannerError></CrBannerError>
        </div>
      )}
      <FormProvider {...methods}>
        <div className={isFormVisible ? "form-enclosure" : "d-none"}>
          <div className="main-form">
            <div className="w-100">
              <div className="edit-appt-wrap">
                <span>Edit Appointment Time</span>
              </div>
              <div className="edit-appt-details">
                Edit appointment for {appointmentInfo.provider_name} on{" "}
                {appointmentStartDate} from {appointmentStartTime.toLowerCase()}{" "}
                - {appointmentEndTime.toLowerCase()}.
              </div>
            </div>
            {isAdmin ? (
              <div className="w-100">
                <div className="form-row">
                  <div className="form-row-item">
                    <FormItem optional={false} label="Start Time">
                      <Controller
                        name="startTime"
                        control={methods.control}
                        render={({ field }) => (
                          <TimerTextField
                            className={"time-style"}
                            field={field}
                            errors={methods.formState.errors.startDate}
                            value={startDate}
                            onChange={(newValue: any) => {
                              startDateHandler(newValue);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 900,
                            }}
                          />
                        )}
                      />
                    </FormItem>
                  </div>

                  <div className="form-row-item">
                    <FormItem optional={false} required={true} label="End Time">
                      <Controller
                        name="endTime"
                        control={methods.control}
                        render={({ field }) => (
                          <TimerTextField
                            className={"time-style"}
                            field={field}
                            errors={methods.formState.errors.endDate}
                            value={endDate}
                            onChange={(newValue: any) => {
                              endDateHandler(newValue);
                            }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              step: 900,
                            }}
                          />
                        )}
                      />
                    </FormItem>
                  </div>
                </div>

                {showReasons &&
                  !hasConflictingPTO &&
                  !isBeforeStartTime &&
                  !ptoConflictCheck && (
                    <div className="form-row antd-100 h-center">
                      <FormItem optional={false} label="">
                        <Controller
                          name="reason"
                          control={methods.control}
                          render={({ field }) => (
                            <>
                              <Select
                                name="reason"
                                placeholder="Select Reason"
                                options={editReason}
                                field={field}
                                errors={methods.formState.errors.cancellationId}
                                onChange={reasonHandler}
                              />
                            </>
                          )}
                        />
                      </FormItem>
                    </div>
                  )}

                {addressLoader && !hasConflictingPTO && (
                  <div className="loading-io w-100 d-flex fx-column gutter-top-50">
                    <div className="load-div w-100 d-flex h-center">
                      <SpinnerV2 />
                    </div>
                    <div className="title-div h-center gutter-top-20">
                      <span>Loading drive time data</span>
                    </div>
                  </div>
                )}

                {showAddressFields && !addressLoader && !hasConflictingPTO && (
                  <DriveTimeMileage
                    initiateAddressLoader={initiateAddressLoader}
                    fetchAddressSuggestions={fetchAddressSuggestions}
                    startingAddressHandler={startingAddressHandler}
                    destinationAddressHandler={destinationAddressHandler}
                    milesHandler={milesHandler}
                    reimburseableMilesHandler={reimburseableMilesHandler}
                    driveTimeHandle={driveTimeHandle}
                    isFirstLastDrive={isFirstLastDrive}
                    setIsExceedingMaxMiles={setIsExceedingMaxMiles}
                    fetchingStartAddress={fetchingStartAddress}
                    fetchingEndAddress={fetchingEndAddress}
                    startingAddressSuggestions={startingAddressSuggestions}
                    setStartingAddressSuggestions={
                      setStartingAddressSuggestions
                    }
                    destinationAddressSuggestions={
                      destinationAddressSuggestions
                    }
                    setDestinationAddressSuggestions={
                      setDestinationAddressSuggestions
                    }
                    fullStartingAddress={fullStartingAddress}
                    fullEndingAddress={fullEndingAddress}
                    setMileAgeCalculation={setMileAgeCalculation}
                    errors={{
                      startingAddress: Array.isArray(
                        methods?.formState?.errors?.startingAddress
                      )
                        ? methods?.formState?.errors?.startingAddress[0]
                        : methods?.formState?.errors?.startingAddress,
                      destinationAddress: Array.isArray(
                        methods?.formState?.errors?.destinationAddress
                      )
                        ? methods?.formState?.errors?.destinationAddress[0]
                        : methods?.formState?.errors?.destinationAddress,
                      isFirstLastDrive: Array.isArray(
                        methods?.formState?.errors?.isFirstLastDrive
                      )
                        ? methods?.formState?.errors?.isFirstLastDrive[0]
                        : methods?.formState?.errors?.isFirstLastDrive,
                    }}
                  />
                )}
                {mileError && (
                  <div className="error-block">
                    <p>
                      Something went wrong while fetching the address or
                      calculating miles. Please start over the process
                    </p>
                  </div>
                )}
                <div className="form-row ant-col-100 txt-area">
                  <FormItem optional={false} label="Note (Optional)">
                    <Controller
                      name="note" // Update controller name to "note"
                      control={methods.control}
                      render={({ field }) => (
                        <TextAreaField // Replace DesktopTimePicker with TextAreaField
                          field={field}
                          errors={methods?.formState?.errors?.note}
                          rows={4} // Optionally specify rows for the text area
                          placeholder="Add appointment notes here :)"
                        />
                      )}
                    />
                  </FormItem>
                </div>
              </div>
            ) : (
              <div>
                <div className="form-row">
                  <FormItem optional={false} label="Start Time">
                    <Controller
                      name="startTime"
                      control={methods.control}
                      render={({ field }) => (
                        <TimerTextField
                          className={"time-style"}
                          field={field}
                          errors={methods.formState.errors.startDate}
                          value={startDate}
                          onChange={(newValue: any) => {
                            startDateHandler(newValue);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 900,
                          }}
                        />
                      )}
                    />
                  </FormItem>
                </div>

                {!startPrestineStatus &&
                  !hasConflictingPTO &&
                  !isBeforeStartTime &&
                  !ptoConflictCheck && (
                    <>
                      <div className="form-row reason-100">
                        <FormItem optional={false} label="">
                          <Controller
                            name="startReason"
                            control={methods.control}
                            render={({ field }) => (
                              <>
                                <Select
                                  name="startReason"
                                  placeholder="Select Reason"
                                  options={editReason}
                                  field={field}
                                  errors={
                                    methods.formState.errors.cancellationId
                                  }
                                  onChange={startReasonHandler}
                                />
                              </>
                            )}
                          />
                        </FormItem>
                      </div>
                    </>
                  )}

                <div className="form-row">
                  <FormItem optional={false} label="End Time">
                    <Controller
                      name="endTime"
                      control={methods.control}
                      render={({ field }) => (
                        <TimerTextField
                          className={"time-style"}
                          field={field}
                          errors={methods.formState.errors.endDate}
                          value={endDate}
                          onChange={(newValue: any) => {
                            endDateHandler(newValue);
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          inputProps={{
                            step: 900,
                          }}
                        />
                      )}
                    />
                  </FormItem>
                </div>

                {!endPrestineStatus &&
                  !hasConflictingPTO &&
                  !isBeforeStartTime &&
                  !ptoConflictCheck && (
                    <>
                      <div className="form-row reason-100">
                        <FormItem optional={false} label="">
                          <Controller
                            name="endReason"
                            control={methods.control}
                            render={({ field }) => (
                              <>
                                <Select
                                  name="endReason"
                                  placeholder="Select Reason"
                                  options={editReason}
                                  field={field}
                                  errors={
                                    methods.formState.errors.cancellationId
                                  }
                                  onChange={endReasonHandler}
                                />
                              </>
                            )}
                          />
                        </FormItem>
                      </div>
                    </>
                  )}
                <div className="form-row ant-col-100 txt-area note-field">
                  <FormItem optional={false} label="Note (Optional)">
                    <Controller
                      name="note" // Update controller name to "note"
                      control={methods.control}
                      render={({ field }) => (
                        <TextAreaField // Replace DesktopTimePicker with TextAreaField
                          field={field}
                          errors={methods?.formState?.errors?.note}
                          rows={4} // Optionally specify rows for the text area
                          placeholder="Add appointment notes here :)"
                        />
                      )}
                    />
                  </FormItem>
                </div>
              </div>
            )}

            {ptoConflictCheck && (
              <div className="loading-io w-100 d-flex fx-column gutter-top-50">
                <div className="load-div w-100 d-flex h-center">
                  <SpinnerV2 />
                </div>
                <div className="title-div h-center gutter-top-20">
                  <span>Checking for PTO Conflicts</span>
                </div>
              </div>
            )}
            <div className="error-time-wrapper">
              {timeValidate && (
                <>
                  <span style={{ color: "#cc3f3f" }}>
                    {isBeforeStartTime ? (
                      <>The start time must be earlier than the end time.</>
                    ) : (
                      <>ABA appointments must be at least 5 minutes.</>
                    )}
                  </span>
                </>
              )}
              {isTimePrestine && !showReasons && !isDriveTime && (
                <>
                  <span style={{ color: "#cc3f3f" }}>
                    The start time or end time have not changed.
                  </span>
                </>
              )}
              {hasConflictingPTO && (
                <PtoConflictErrors
                  ptoStartDate={ptoStartDate}
                  ptoEndDate={ptoEndDate}
                />
              )}
            </div>

            <div className="submit-button">
              <button
                disabled={
                  isBeforeStartTime ||
                  hasConflictingPTO ||
                  mileAgeCalculation ||
                  !commonFieldsChanged ||
                  isExceedingMaxMiles
                }
                type="submit"
                onClick={methods.handleSubmit(submitForm, submitError)}
              >
                Update
              </button>
            </div>
          </div>
        </div>
        {showConfirm && (
          <ConfirmEdit
            isAdmin={isAdmin}
            appointment={appointmentInfo}
            isDriveTime={isDriveTime}
            startingAddress={fullStartingAddress}
            destinationAddress={fullEndingAddress}
            showReasons={showReasons}
          ></ConfirmEdit>
        )}
      </FormProvider>
    </EditAppointmentWrapper>
  );
}; //end of the component

export default React.memo(EditAppointment);
